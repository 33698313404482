let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
let Envname = "prodction";

if (Envname === "prodction") {
  console.log("Set Demo Config");
  key = {
    baseUrl: `https://prodapi.vinnex.biz`,
    frontend: "https://www.vinnex.biz",
    NetworkId: 56,
    frontUrl: "https://www.vinnex.biz/",
    Icocontract: "0xE12d4bF8b1d8297aE88f3FCA8d19561275383107",
    // Busdaddress: "0x640e5ddff439a912c637cd9ce27393915240d5d4",
    xtrcaddress: "0x4301D05622db29bAd61DC2fe71ba6F528Fe6C340",
    Busdaddress: "0xC67E20354AaE72F669cdE0a66C37c1C5cc0dd752",
    // TarusCOnt : "0x37e70f8a263bb655bb04f04b041225dfd0fa19fe", 0xae7636a60d672c7d2e2a575a74050c62a3289119

    rpcUrl: "https://bsc-rpc.publicnode.com",
    toasterOption: toasterOption,
    defaultId: 101,
    defaultId2: 102,
    bscscan: "https://bscscan.com/tx/" //https://bscscan.com/tx/
  };
} else if (Envname === "demo") {
  console.log("Set Demo Config");
  key = {
    baseUrl: `https://vinnexapi.wearedev.team`,
    frontend: "https://vinnex-main-site.pages.dev",
    NetworkId: 97,
    frontUrl: "https://vinnex-main-site.pages.dev/",
    Icocontract: "0xCDe75c52d63C246232745FbcBCc4954c298085e1",
    // Busdaddress: "0x640e5ddff439a912c637cd9ce27393915240d5d4",
    Busdaddress: "0x37E70f8a263BB655bB04f04B041225Dfd0fa19Fe",
    xtrcaddress: "0x8c222b8035c6CE9838588ee699156b21A15DB134",
    // TarusCOnt : "0x37e70f8a263bb655bb04f04b041225dfd0fa19fe", 0xae7636a60d672c7d2e2a575a74050c62a3289119

    rpcUrl: "https://data-seed-prebsc-1-s2.binance.org:8545",
    toasterOption: toasterOption,
    defaultId: 1,
    bscscan: "https://testnet.bscscan.com/tx/" //https://bscscan.com/tx/
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";
  key = {
    baseUrl: `${API_URL}:3040`,
    frontend: "http://localhost:3000",
    NetworkId: 97,
    frontUrl: "http://localhost:3000/",
    Icocontract: "0x34483Cc5bA3F01ef0E13021bA904035d3BF11041",

    Busdaddress: "0x37E70f8a263BB655bB04f04B041225Dfd0fa19Fe",
    xtrcaddress: "0x8c222b8035c6CE9838588ee699156b21A15DB134",
    // Busdaddress: "0xae7636a60d672c7d2e2a575a74050c62a3289119",

    rpcUrl: "https://data-seed-prebsc-1-s2.binance.org:8545",
    toasterOption: toasterOption,
    defaultId: 101,
    defaultId2: 102,
    bscscan: "https://testnet.bscscan.com/tx/"
  };
}

export default key;
