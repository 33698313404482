import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import user3 from "../assets/images/user_3.svg";
import user1 from "../assets/images/user_1.svg";
import user2 from "../assets/images/user_2.svg";
import search from "../assets/images/search.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import config from "../lib/config.js";
import IcoContractAbi from "../Abi/IcoContractAbi.json";
import BusdContractAbi from "../Abi/BusdContractAbi.json";
import XTRCABI from "../Abi/XTrcABI.json";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useNavigate, Link, useParams } from "react-router-dom";

import {
  retopUpbinary,
  getUserDetails,
  seaarchUserTree,
  getinvesmenthistory,
  get_binaryhistory,
  get_PrimeHist,
  getusesettings,
  getWithBalance,
  getSignature,
  getSignaturePrime,
  getSignatureBalnce,
  getSignatureFuture,
  updateStatus,
  cancelStatus,
  cancelStatusBinary,
  cancelFutureStatus,
  cancelPrimeStatus,
  getWithdrawHis,
  updateRejeteStatus,
  getBinaryHis,
  AmountDeduction
} from "../Action/ApiAction.js";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

export default function Dashboard_member(props) {
  const { addr } = useParams();
  var navigate = useNavigate();
  const Connection = useSelector((state) => state.walletConnection);
  const [isLoader, setLoader] = useState(false);
  const [copystatus, setcopystatus] = useState(false);
  const [copystatus1, setcopystatus1] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const [referraldata, setreferraldata] = useState([]);

  const [searchId, setSearchId] = useState(userdata?.userID);
  const [srchLR, setSrchLR] = useState(false);

  const [binaryTree, setbinaryTree] = useState([]);

  const [tokenpricevalue, settokenpricevalue] = useState();
  const [tokenpricevaluextrx, settokenpricevaluextrc] = useState();
  const [historyTree, setHistoryTree] = useState([]);
  const [countTest, setCountTest] = useState(historyTree.length);
  const [amount, setamount] = useState("");
  const [invesmentData, setinvesmentData] = useState([]);
  const [roidata, setroidata] = useState([]);
  const [refBon, setRefBon] = useState([]);
  const [ceilinglimit, setceilinglimit] = useState(0);
  const [earnedpercatge, setearnedpercatge] = useState(0);
  const [roipercentage, setroipercentage] = useState(0);
  const [binaryhistory, setbinaryhistory] = useState();
  const [setting, setsetting] = useState();
  const [withBal, setWithBal] = useState(0);
  const [roiHis, setRoiHis] = useState([]);
  const [futureHis, setFutureHis] = useState([]);
  const [primeHist, setPrimeHist] = useState([]);
  const [primeHis, setPrimeHis] = useState([]);
  const [BirDirHis, setBirDirHisHis] = useState([]);
  const [matchingHis, setMatchingHis] = useState([]);
  const [amountdd, setAmountdd] = useState([]);
  console.log(historyTree, "historyTree");
  const onhandleclose = () => {
    setamount("");
  };

  useEffect(() => {
    if (localStorage.getItem("iswalletconnect") == "yes") {
      getuserdata();
      getprimehhistory();
      getdepositbinaryhistory();
      settingdata();
      getWithBalan();
      getWithdrawHistory();
    } else {
      // navigate("/");
    }
  }, [Connection.address, addr]);

  useEffect(() => {
    gethistory();
    searchClick();
  }, [Connection.address, userdata, addr]);

  const settingdata = async () => {
    const data = await getusesettings();
    console.log(data, "frsefvrdsfvrdfv");

    setsetting(data.result);
  };

  console.log(setting, "setting");

  // setTimeout(() => {
  //   if (userdata.binarystatus == 1) {

  //   } else {
  //     navigate("/");
  //   }
  // }, 2000);

  const getWithBalan = async () => {
    if (Connection?.address) {
      var reqdata = {
        address: addr
      };
      const { result, status } = await getWithBalance(reqdata);
      console.log(result, status, "wdwdwfwsfcws");
      setWithBal(result);
    }
  };

  const getWithdrawHistory = async () => {
    if (Connection.address) {
      const payload = {
        address: addr
      };
      const data = await getWithdrawHis(payload);
      console.log(data, "dsfvfv");
      const filDataRoi = data?.result?.filter((el) => el.type == 1);
      setRoiHis(filDataRoi);
      const filDataFut = data?.result?.filter((el) => el.type == 2);
      setFutureHis(filDataFut);
      const filDataBirDir = data?.result?.filter((el) => el.type == 3);
      setBirDirHisHis(filDataBirDir);
      const filDataPri = data?.result?.filter((el) => el.type == 5);
      console.log(filDataPri, "filDataPri");
      setPrimeHis(filDataPri);
    }
  };
  const getprimehhistory = async () => {
    try {
      if (Connection.address) {
        let payload = {
          address: addr
        };
        const { result, status } = await get_PrimeHist(payload);
        if (status) {
          await result.map((el, i) => {
            el.SSNo = i + 1;
          });
          setPrimeHist(result);
        } else {
          setPrimeHist([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getdepositbinaryhistory = async () => {
    if (Connection?.address != "") {
      var reqdata = {
        address: addr
      };
      const { result, status } = await get_binaryhistory(reqdata);
      console.log(result, status, "tfthrthfbdg");

      if (status) {
        await result?.map((ele, i) => {
          ele.SSNo = i + 1;
        });

        setbinaryhistory(result);
      } else {
        setbinaryhistory([]);
      }
    }
  };

  // const withdrawFuture = async (id, dateFrom, amount) => {
  //   var date = new Date(dateFrom);
  //   date.setDate(date.getDate() + setting?.Roiperiod);
  //   // date.setMinutes(date.getMinutes() + setting?.Roiperiod);
  //   let date1 = new Date(date).getTime();
  //   let date2 = new Date().getTime();
  //   console.log(date1, date2, "date1");
  //   if (date1 > date2) {
  //     toastAlert(
  //       "error",
  //       `You need to wait ${setting?.Roiperiod} days`,
  //       "error"
  //     );
  //     return false;
  //   }
  //   setLoader(true);
  //   let rand = Math.floor(Math.random() * 1000000 + 1);

  //   var data = {
  //     randNumber: rand,
  //     address: Connection.address,
  //     id: id,
  //     amount: amount
  //   };

  //   const signdata = await getSignatureFuture(data);
  //   console.log(signdata);
  //   if (signdata?.data?.status) {
  //     var web3 = new Web3(Connection?.web3);
  //     var icoContract = new web3.eth.Contract(
  //       IcoContractAbi,
  //       config.Icocontract
  //     );

  //     var amt = amount * 1e18;
  //     console.log(
  //       amt,
  //       Connection.address,
  //       rand,
  //       signdata?.data?.result?.signature,
  //       "DetailsSign"
  //     );
  //     await icoContract.methods
  //       .withdraw(
  //         amt.toString(),
  //         Connection.address,
  //         rand,
  //         signdata?.data?.result?.signature,
  //         "2",
  //         (userdata?.tokenType).toString()
  //       )
  //       .send({ from: Connection.address })
  //       .then(async (res) => {
  //         const reqbody = {
  //           address: Connection.address,
  //           transactionHash: res.transactionHash,
  //           signature: signdata?.data?.result?.signature
  //         };
  //         var dataUpd = await updateStatus(reqbody);
  //         if (dataUpd?.data?.status) {
  //           toastAlert(
  //             "success",
  //             "Future wallet withdraw successfully",
  //             "success"
  //           );
  //           setLoader(false);
  //           window.location.reload();
  //           return false;
  //         }
  //       })
  //       .catch((e) => {
  //         toastAlert(
  //           "error",
  //           "Future wallet withdraw rejected please check future wallet withdraw history",
  //           "error"
  //         );
  //         setTimeout(() => {
  //           window.location.reload(true);
  //         }, 4000);
  //         setLoader(false);
  //         return false;
  //       });
  //   } else {
  //     toastAlert("error", "Error Try Again ", "error");
  //     setLoader(false);
  //     return false;
  //   }
  // };

  // const withdrawBalance = async () => {
  //   var amount =
  //     userdata.directIncome +
  //     userdata.binaryIncome -
  //     ((userdata.directIncome + userdata.binaryIncome) *
  //       setting?.adminDeduction) /
  //       100 -
  //     (userdata.totalWithdraw + userdata.companyAmountDeducted);
  //   if (amount <= 0) {
  //     toastAlert("error", "Amount must be greater than zero", "error");
  //     return false;
  //   }

  //   if (amount < setting?.minimumwithdrawal) {
  //     toastAlert(
  //       "error",
  //       "Amount must be greater than Minimum limit " +
  //         setting?.minimumwithdrawal
  //     );
  //     return false;
  //   }
  //   setLoader(true);
  //   let rand = Math.floor(Math.random() * 1000000 + 1);

  //   var data = {
  //     amount: amount,
  //     randNumber: rand,
  //     address: Connection.address
  //   };

  //   const signdata = await getSignatureBalnce(data);
  //   console.log(signdata);
  //   if (signdata?.data?.status) {
  //     var web3 = new Web3(Connection?.web3);
  //     var icoContract = new web3.eth.Contract(
  //       IcoContractAbi,
  //       config.Icocontract
  //     );

  //     var amountVal = amount * 10 ** 18;
  //     var amt = amountVal;
  //     amt = convert(amt);
  //     amt = amt.toString();

  //     await icoContract.methods
  //       .withdraw(
  //         amt,
  //         Connection.address,
  //         rand,
  //         signdata?.data?.result?.signature,
  //         "3",
  //         (userdata?.tokenType).toString()
  //       )
  //       .send({ from: Connection.address })
  //       .then(async (res) => {
  //         setTimeout(async () => {
  //           const reqbody = {
  //             address: Connection.address,
  //             transactionHash: res.transactionHash,
  //             signature: signdata?.data?.result?.signature
  //           };
  //           var dataUpd = await updateStatus(reqbody);
  //           console.log(dataUpd, "dataUpd");
  //           if (dataUpd?.data?.status) {
  //             toastAlert("success", "Withdraw successfully", "success");
  //             window.location.reload();
  //             setLoader(false);
  //             return false;
  //           }
  //         }, 4000);
  //       })
  //       .catch(async (e) => {
  //         toastAlert(
  //           "error",
  //           "Available balance withdraw rejected please check withdraw history",
  //           "error"
  //         );
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 4000);
  //         // const reqbody = {
  //         //   address: Connection.address,
  //         //   signature: signdata?.data?.result?.signature
  //         // };
  //         // const rejData = await updateRejeteStatus(reqbody);
  //         setLoader(false);
  //         return false;
  //       });
  //   } else {
  //     toastAlert("error", signdata?.data?.message, "error");
  //     setLoader(false);
  //     return false;
  //   }
  // };

  // const withdrawPrimeUser = async () => {
  //   try {
  //     var amount =
  //       userdata.primeIncome -
  //       (userdata.primeIncome * setting?.adminDeduction) / 100 -
  //       userdata.totalWithdrawPrime;

  //     if (amount <= 0) {
  //       toastAlert("error", "Amount must be greater than zero", "error");
  //       return false;
  //     }

  //     if (amount < setting?.primelimit) {
  //       toastAlert(
  //         "error",
  //         "Amount must be greater than Minimum limit " + setting?.primelimit,
  //         "error"
  //       );
  //       return false;
  //     }
  //     setLoader(true);
  //     let rand = Math.floor(Math.random() * 1000000 + 1);
  //     var data = {
  //       amount: amount,
  //       randNumber: rand,
  //       address: Connection.address
  //     };

  //     const signdata = await getSignaturePrime(data);
  //     console.log(signdata, "result");
  //     if (signdata?.data?.status) {
  //       var web3 = new Web3(Connection?.web3);
  //       var icoContract = new web3.eth.Contract(
  //         IcoContractAbi,
  //         config.Icocontract
  //       );

  //       var amountVal = amount * 10 ** 18;
  //       var amt = amountVal;
  //       amt = convert(amt);
  //       amt = amt.toString();

  //       await icoContract.methods
  //         .withdraw(
  //           amt,
  //           Connection.address,
  //           rand,
  //           signdata?.data?.result?.signature,
  //           "5",
  //           (userdata?.tokenType).toString()
  //         )
  //         .send({ from: Connection.address })
  //         .then(async (res) => {
  //           const reqbody = {
  //             address: Connection.address,
  //             transactionHash: res.transactionHash,
  //             signature: signdata?.data?.result?.signature
  //           };
  //           var dataUpd = await updateStatus(reqbody);
  //           if (dataUpd?.data?.status) {
  //             toastAlert(
  //               "success",
  //               "Prime Bonus withdraw successfully",
  //               "success"
  //             );
  //             setLoader(false);
  //             window.location.reload();
  //             return false;
  //           }
  //         })
  //         .catch((e) => {
  //           console.log(e, "er");
  //           setLoader(false);
  //           toastAlert(
  //             "error",
  //             "Withdraw Prime Bonus rejected please check Prime Bonus withdraw history",
  //             "error"
  //           );
  //           setTimeout(() => {
  //             window.location.reload();
  //           }, 4000);
  //           return false;
  //         });
  //     } else {
  //       toastAlert("error", "Error Try Again2121412 ", "error");
  //       setLoader(false);
  //       return false;
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const withdrawRoiUser = async () => {
  //   var amount =
  //     userdata.roiIncome -
  //     (userdata.roiIncome * setting?.adminDeduction) / 100 -
  //     userdata.totalWithdrawRoi;

  //   if (amount <= 0) {
  //     toastAlert("error", "Amount must be greater than zero", "error");
  //     return false;
  //   }

  //   if (amount < setting?.minimumRoi) {
  //     toastAlert(
  //       "error",
  //       "Amount must be greater than Minimum limit " + setting?.minimumRoi,
  //       "error"
  //     );
  //     return false;
  //   }
  //   setLoader(true);
  //   let rand = Math.floor(Math.random() * 1000000 + 1);
  //   var data = {
  //     amount: amount,
  //     randNumber: rand,
  //     address: Connection.address
  //   };

  //   const signdata = await getSignature(data);
  //   console.log(signdata, "result");
  //   if (signdata?.data?.status) {
  //     var web3 = new Web3(Connection?.web3);
  //     var icoContract = new web3.eth.Contract(
  //       IcoContractAbi,
  //       config.Icocontract
  //     );

  //     var amountVal = amount * 10 ** 18;
  //     var amt = amountVal;
  //     amt = convert(amt);
  //     amt = amt.toString();

  //     await icoContract.methods
  //       .withdraw(
  //         amt,
  //         Connection.address,
  //         rand,
  //         signdata?.data?.result?.signature,
  //         "1",
  //         (userdata?.tokenType).toString()
  //       )
  //       .send({ from: Connection.address })
  //       .then(async (res) => {
  //         const reqbody = {
  //           address: Connection.address,
  //           transactionHash: res.transactionHash,
  //           signature: signdata?.data?.result?.signature
  //         };
  //         var dataUpd = await updateStatus(reqbody);
  //         if (dataUpd?.data?.status) {
  //           toastAlert("success", "ROI withdraw successfully", "success");
  //           setLoader(false);
  //           window.location.reload();
  //           return false;
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e, "er");
  //         setLoader(false);
  //         toastAlert(
  //           "error",
  //           "Withdraw ROI rejected please check ROI withdraw history",
  //           "error"
  //         );
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 4000);
  //         return false;
  //       });
  //   } else {
  //     toastAlert("error", "Error Try Again2121412 ", "error");
  //     setLoader(false);
  //     return false;
  //   }
  // };

  // const cancelstatus = async (amount, rand, sig, type) => {
  //   var web3 = new Web3(Connection?.web3);
  //   var icoContract = new web3.eth.Contract(IcoContractAbi, config.Icocontract);

  //   var amountVal = amount * 10 ** 18;
  //   var amt = amountVal;
  //   amt = convert(amt);
  //   amt = amt.toString();
  //   setLoader(true);
  //   await icoContract.methods
  //     .cancelWithdraw(amt, Connection.address, rand, sig, type)
  //     .send({ from: Connection.address })
  //     .then(async (res) => {
  //       const reqbody = {
  //         transactionHash: res.transactionHash,
  //         signature: sig,
  //         address: Connection.address
  //       };
  //       var canData = await cancelStatus(reqbody);
  //       console.log(canData?.data?.status, "111111edrfde");
  //       if (canData?.data?.status) {
  //         toastAlert("success", "Cancel successfully", "success");
  //         setLoader(false);
  //         window.location.reload();
  //         return false;
  //       } else {
  //         toastAlert("error", "Cancel Failed", "success");
  //         setLoader(false);
  //         return false;
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "ewfecedvfeferorr");
  //       toastAlert("error", "Rejected", "error");
  //       setLoader(false);
  //       return false;
  //     });
  // };

  // const cancelstatusbinary = async (amount, rand, sig, type) => {
  //   var web3 = new Web3(Connection?.web3);
  //   var icoContract = new web3.eth.Contract(IcoContractAbi, config.Icocontract);

  //   var amountVal = amount * 10 ** 18;
  //   var amt = amountVal;
  //   amt = convert(amt);
  //   amt = amt.toString();
  //   setLoader(true);
  //   await icoContract.methods
  //     .cancelWithdraw(amt, Connection.address, rand, sig, type)
  //     .send({ from: Connection.address })
  //     .then(async (res) => {
  //       const reqbody = {
  //         transactionHash: res.transactionHash,
  //         signature: sig,
  //         address: Connection.address
  //       };
  //       var canData = await cancelStatusBinary(reqbody);
  //       console.log(canData, "canData");
  //       if (canData?.data?.status) {
  //         toastAlert("success", "Cancel successfully", "success");
  //         setLoader(false);
  //         window.location.reload();
  //         return false;
  //       } else {
  //         toastAlert("error", "Cancel Failed", "success");
  //         setLoader(false);
  //         return false;
  //       }
  //     })
  //     .catch((e) => {
  //       toastAlert("error", "Rejected", "error");
  //       setLoader(false);
  //       return false;
  //     });
  // };

  // const cancelprimestatus = async (amount, rand, sig, type, id) => {
  //   var web3 = new Web3(Connection?.web3);
  //   var icoContract = new web3.eth.Contract(IcoContractAbi, config.Icocontract);

  //   var amountVal = amount * 10 ** 18;
  //   var amt = amountVal;
  //   amt = convert(amt);
  //   amt = amt.toString();
  //   setLoader(true);
  //   await icoContract.methods
  //     .cancelWithdraw(amt, Connection.address, rand, sig, type)
  //     .send({ from: Connection.address })
  //     .then(async (res) => {
  //       const reqbody = {
  //         transactionHash: res.transactionHash,
  //         signature: sig,
  //         address: Connection.address,
  //         id: id
  //       };
  //       var canData = await cancelPrimeStatus(reqbody);
  //       console.log(canData.data, canData.data?.status, "canData12", type);
  //       if (canData?.data?.status) {
  //         toastAlert("success", "Cancel successfully", "success");
  //         setLoader(false);
  //         window.location.reload();
  //         return false;
  //       } else {
  //         toastAlert("error", "Cancel Failed", "success");
  //         setLoader(false);
  //         return false;
  //       }
  //     })
  //     .catch((e) => {
  //       toastAlert("error", "Rejected", "error");
  //       setLoader(false);
  //       return false;
  //     });
  // };

  // const cancelfuturestatus = async (amount, rand, sig, type, id) => {
  //   var web3 = new Web3(Connection?.web3);
  //   var icoContract = new web3.eth.Contract(IcoContractAbi, config.Icocontract);

  //   var amountVal = amount * 10 ** 18;
  //   var amt = amountVal;
  //   amt = convert(amt);
  //   amt = amt.toString();
  //   setLoader(true);
  //   await icoContract.methods
  //     .cancelWithdraw(amt, Connection.address, rand, sig, type)
  //     .send({ from: Connection.address })
  //     .then(async (res) => {
  //       const reqbody = {
  //         transactionHash: res.transactionHash,
  //         signature: sig,
  //         address: Connection.address,
  //         id: id
  //       };
  //       var canData = await cancelFutureStatus(reqbody);
  //       if (canData?.data?.status) {
  //         toastAlert("success", "Cancel successfully", "success");
  //         setLoader(false);
  //         window.location.reload();
  //         return false;
  //       } else {
  //         toastAlert("error", "Cancel Failed", "success");
  //         setLoader(false);
  //         return false;
  //       }
  //     })
  //     .catch((e) => {
  //       toastAlert("error", "Rejected", "error");
  //       setLoader(false);
  //       return false;
  //     });
  // };

  const getuserdata = async () => {
    if (Connection.web3) {
      var reqdata = {
        address: addr
      };

      const data = await getUserDetails(reqdata);
      setLoader(true);
      var web3 = new Web3(Connection?.web3);
      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );
      const tokenprice = await icoContract.methods.tokenPrice().call();
      const tokenprice1 = await icoContract.methods.tokenPrice1().call();

      var tokenvalue = tokenprice / 1e8;
      var tokenvalue1 = tokenprice1 / 1e8;
      settokenpricevalue(tokenvalue);
      settokenpricevaluextrc(tokenvalue1);
      console.log(data, "123");
      if (data?.data?.success == true) {
        setuserdata(data?.data?.data);
        setreferraldata(data?.data?.result);
        setroidata(data?.data?.roidata);
        setSearchId(data?.data?.data.userID);
        var roidatapercentage = (data?.data?.roidata.length / 220) * 100;
        var roicovertResult = await convert(roidatapercentage);
        console.log(roicovertResult, "vvvvv");
        setroipercentage(roicovertResult);
        setceilinglimit(data?.data?.data?.treePercentage);

        var result =
          (data?.data?.data.depositAmountinBinary /
            (data?.data?.data?.depositAmountinBinary *
              setting?.maxAmountreceivedLimit)) *
          100;
        console.log(result, "dddcsffe");
        var covertResult = await convert(result);
        console.log(covertResult, "covertResult");
        setearnedpercatge(covertResult);
        if (data?.data?.data.communityOnly == 0) {
          navigate("/dashboard-community");
        }
        setLoader(false);
      } else {
        navigate("/join-now");
        setuserdata([]);
        setLoader(false);
        setreferraldata([]);
        setroidata([]);
        setceilinglimit(0);
      }
    }
  };

  const Retopupbinary = async () => {
    try {
      setLoader(true);

      if (amount == "" || amount == 0) {
        toastAlert("error", "Amount Required", "error");
        setLoader(false);
        return false;
      }

      if (amount < setting?.binaryInvestAmount || amount % 10 !== 0) {
        toastAlert(
          "error",
          `Amount must be multiples of 10 and greater than ${setting?.binaryInvestAmount}`
        );
        setLoader(false);
        return false;
      }

      var web3 = new Web3(Connection.web3);

      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );

      var BusdBlanace = new web3.eth.Contract(
        BusdContractAbi,
        config.Busdaddress
      );
      var XtrcBlanace = new web3.eth.Contract(XTRCABI, config.xtrcaddress);
      const balance = await BusdBlanace.methods
        .balanceOf(Connection.address)
        .call();
      const balanceX = await XtrcBlanace.methods
        .balanceOf(Connection.address)
        .call();
      if (Number(userdata?.tokenType) == 0) {
        if (balance / 1e18 < amount * (1 / tokenpricevalue)) {
          toastAlert("error", "Your TAURAS Token Balance is low", "error");
          setLoader(false);
          return false;
        }
      } else {
        if (balanceX / 1e18 < amount * (1 / tokenpricevaluextrx)) {
          toastAlert("error", "Your XTAURAS Token Balance is low", "error");
          setLoader(false);
          return false;
        }
      }

      const tokenprice = await icoContract.methods.tokenPrice().call();

      var updateprice = tokenprice / 1e8;
      var approveamt = await convert(amount * 1e18);
      let TokenCont = "";
      // approveamt = approveamt * (1 / tokenpricevalue);
      if (Number(userdata?.tokenType) == 0) {
        approveamt = approveamt * (1 / tokenpricevalue);
        TokenCont = BusdBlanace;
      } else {
        approveamt = approveamt * (1 / tokenpricevaluextrx);
        TokenCont = XtrcBlanace;
      }
      approveamt = convert(approveamt);

      console.log(approveamt, "tokenprice");

      await TokenCont.methods
        .approve(config.Icocontract, approveamt.toString())
        .send({ gasLimit: 250000, from: Connection.address })
        .then(async (res) => {
          await icoContract.methods
            .retopupVinnex(
              approveamt.toString(),
              (userdata?.tokenType).toString()
            )
            .send({ from: Connection.address })
            .then(async (res) => {
              const reqbody = {
                address: Connection.address,
                transactionHash: res.transactionHash,
                amount: amount,
                tokenType: userdata?.tokenType
              };

              const result = await retopUpbinary(reqbody);

              if (result.data.success == true) {
                toastAlert("success", "Retopup successfully", "success");
                window.$("#" + "Retopup-binary").modal("hide");
                setLoader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const Funn = () => {
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.user == searchId) {
        setSrchLR(true);
        break;
      }
    }
  };
  useEffect(() => {
    Funn();
  }, [searchId]);

  const searchchange = (e) => {
    var ValNum = parseInt(e.target.value);
    // console.log(e.target.value,"e.target.valuee.target.valuee.target.value")
    if (ValNum == null) {
      setSearchId(userdata.userID);
    } else {
      setSearchId(ValNum);
    }

    // alert(VaNum);
    for (let i = 0; i < binaryTree.length; i++) {
      if (binaryTree[i].items.key == ValNum) {
        setSrchLR(true);
        break;
      } else {
        setSrchLR(false);
      }
    }
  };

  const searchClick = async () => {
    let payload = {
      searchId: searchId,
      userId: userdata?.userID
    };
    const data = await seaarchUserTree(payload);
    console.log(data, "datadata_____");
    const indexToMatch = data.user.Data.findIndex(
      (ele) => ele?.items?.key == userdata.userID
    );
    console.log(indexToMatch, "indexToMatch");
    let newArray;
    if (indexToMatch !== -1) {
      newArray = data.user.Data.slice(indexToMatch);
      newArray.push({
        _id: "635baa912978337ce386f3bc",
        type: 1,
        items: {
          key: 0,
          left: 0,
          right: 0,
          level: 0,
          parent: 0
        }
      });
      setbinaryTree(newArray);
      console.log(newArray, "newArray");
    }
  };

  var userLevleft;
  var userLevright;

  var userLevtTwoleft;
  var userLevTworight;

  var userLevtThreeleft;
  var userLevThreeright;

  console.log(binaryTree, "binaryTree");

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }
  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const gethistory = async () => {
    if (Connection?.address && userdata) {
      var reqdata = {
        address: addr,
        currId: userdata?.userID
      };
      console.log(reqdata, "______++++++++_)");
      const { result, status, result1 } = await getinvesmenthistory(reqdata);
      console.log(result, status, "tfthrthfbdg", result1);

      if (status) {
        await result?.map((ele, i) => {
          ele.SSNo = i + 1;
        });

        setinvesmentData(result);
        setRefBon(result1);
      } else {
        setinvesmentData([]);
      }
    }
  };

  async function CopyFunction(text) {
    navigator.clipboard.writeText(text);
    setcopystatus(true);
    setTimeout(() => {
      setcopystatus(false);
    }, 2000);
    // toast.success("Copied!", toasterOption);
  }
  async function CopyFunction1(text) {
    navigator.clipboard.writeText(text);
    setcopystatus1(true);
    setTimeout(() => {
      setcopystatus1(false);
    }, 2000);
    // toast.success("Copied!", toasterOption);
  }

  useEffect(() => {
    getMatchingHIs();
    AmtDeduction();
  }, [Connection.address]);

  const getMatchingHIs = async () => {
    if (Connection.address) {
      var reqdata = {
        address: addr
      };
      const data = await getBinaryHis(reqdata);
      console.log(data, "adadadadada");
      setMatchingHis(data?.result?.data);
    }
  };

  const AmtDeduction = async () => {
    if (Connection.address) {
      var reqdata = {
        address: addr
      };
      const data = await AmountDeduction(reqdata);
      console.log(data, "adadadadada");
      setAmountdd(data?.result?.data);
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => row.SSNo
    },
    // {
    //   name: "Date",
    //   width: "250PX",
    //   selector: (row) => moment(row.createdAt).format("MMMM,Do YYYY, hh:mm A")
    // },
    {
      name: "Net amount",
      selector: (row, index) => row.amount?.toFixed(4)
    },
    {
      name: "Hash",
      selector: (row, index) => halfAddrShow(row.transHash)
    },

    {
      name: "Status",
      width: "100px",
      selector: (row, index) =>
        row.status == 1 ? (
          <span style={{ color: "green" }}> Active</span>
        ) : (
          <span style={{ color: "red" }}>Inactive</span>
        )
    },

    {
      name: "Join Date",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    },
    // {
    //   name: "Expire Date",
    //   selector: (row, index) =>
    //     moment(row.createdAt)
    //       .add(setting?.Roiperiod, "d")
    //       .format("MMM,Do YYYY, hh:mm A")
    // },
    {
      name: "ROI Remaining Days",
      selector: (row) => (
        <span>
          {DateDiff(moment(row.createdAt).add(setting?.Roiperiod, "d"))} day
          left to complete ROI
        </span>
      )
    }
  ];
  const Primecolumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    {
      name: "From ID",
      selector: (row) => row.frmuserID
    },
    {
      name: "Amount",
      selector: (row) => row.amountPrime
    },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];
  const columnsreferral = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },

    {
      name: "User Id",
      selector: (row, index) => row.originalReferrerID
    },
    {
      name: "Child Id",
      selector: (row, index) => row.userID
    },
    // {
    //   name: "ROI Amount",
    //   selector: (row, index) => row.directIncome
    // },
    // {
    //   name: "Level",
    //   selector: (row, index) => row.uplinerID
    // },
    {
      name: "Package",
      selector: (row, index) => row.depositAmountinBinary
    },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];
  // const handleChange = () => {

  // }
  const ROIcolumns = [
    {
      name: "S.No",
      width: "100px",
      selector: (row, index) => index + 1
    },
    {
      name: "User Id",
      width: "100px",
      selector: (row) => row.userID
    },
    {
      name: "Package",
      width: "100px",
      selector: (row, index) => row.amount
    },
    {
      name: "Investment Id",
      selector: (row, index) => row.investmentId
    },
    {
      name: "Amount",
      width: "150px",
      selector: (row, index) =>
        (
          row.amountRoi -
          (parseFloat(row.amountRoi) * setting?.adminDeduction) / 100
        )?.toFixed(4)
    },
    {
      name: "Percentage",
      // width:'100px',
      selector: (row, index) => row?.percent + " %"
    },

    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];

  const Matchingcolumns = [
    {
      name: "S.No",
      width: "100px",
      selector: (row, index) => index + 1
    },
    {
      name: "User Id",
      // width: "100px",
      selector: (row) => row.userID
    },
    {
      name: "Package",
      // width: "100px",
      selector: (row, index) => row.amount
    },

    {
      name: "Amount",
      // width: "150px",
      selector: (row, index) =>
        (
          row.amountBinary -
          (parseFloat(row.amountBinary) * setting?.adminDeduction) / 100
        )?.toFixed(4)
    },
    {
      name: "Percentage",
      // width:'100px',
      selector: (row, index) => row?.percentage + " %"
    },

    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];

  const RefBonCol = [
    {
      name: "S.no",
      selector: (row, index) => index + 1
    },
    {
      name: "User Id",
      selector: (row) => userdata.userID
    },
    {
      name: "Child Id",
      selector: (row) => row.userID
    },
    {
      name: "Package",
      selector: (row, index) => row.treePercentage
    },
    {
      name: "Percentage",
      selector: (row, index) =>
        userdata.userID == parseFloat(row.directReferalId1)
          ? setting?.First + " %"
          : userdata.userID == parseFloat(row.directReferalId2)
          ? setting?.Second + " %"
          : userdata.userID == parseFloat(row.directReferalId3)
          ? setting?.Third + " %"
          : ""
    },
    {
      name: "Level",
      selector: (row, index) =>
        userdata.userID == row.directReferalId1
          ? 1
          : userdata.userID == row.directReferalId2
          ? 2
          : userdata.userID == row.directReferalId3
          ? 3
          : ""
    },
    {
      name: "Amount",
      selector: (row, index) =>
        userdata.userID == parseFloat(row.directReferalId1)
          ? (
              row.directReferalAmo1 -
              (row.directReferalAmo1 * setting?.adminDeduction) / 100
            ).toFixed(4)
          : userdata.userID == parseFloat(row.directReferalId2)
          ? (
              row.directReferalAmo2 -
              (row.directReferalAmo2 * setting?.adminDeduction) / 100
            ).toFixed(4)
          : userdata.userID == parseFloat(row.directReferalId3)
          ? (
              row.directReferalAmo3 -
              (row.directReferalAmo3 * setting?.adminDeduction) / 100
            ).toFixed(4)
          : ""
    },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];
  const roiHisCol = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    {
      name: "Amount",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "Status",
      selector: (row) =>
        row.Status == 1 ? "Created" : row.Status == 2 ? "Approved" : "Cancel"
    },
    // {
    //   name: "Action",
    //   selector: (row) =>
    //     row.Status == 1 ? (
    //       <button
    //         className="primary_btn py-1 px-4"
    //         onClick={() =>
    //           cancelstatus(row.amount, row.nonce, row.sig, row.type)
    //         }
    //       >
    //         Cancel
    //       </button>
    //     ) : row.Status == 2 ? (
    //       ""
    //     ) : (
    //       ""
    //     )
    // },
    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];
  const futureHisCol = [
    {
      name: "S.No",
      width: "100px",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      width: "150px",
      selector: (row) => row.userID
    },
    {
      name: "Amount",
      width: "150px",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "Status",
      width: "200px",
      selector: (row) =>
        row.Status == 1 ? "Created" : row.Status == 2 ? "Approved" : "Cancel"
    },
    // {
    //   name: "Action",
    //   width: "200px",
    //   selector: (row) =>
    //     row.Status == 1 ? (
    //       <button
    //         className="primary_btn py-1 px-4"
    //         onClick={() =>
    //           cancelfuturestatus(
    //             row.amount,
    //             row.nonce,
    //             row.sig,
    //             row.type,
    //             row._id
    //           )
    //         }
    //       >
    //         Cancel
    //       </button>
    //     ) : row.Status == 2 ? (
    //       ""
    //     ) : (
    //       ""
    //     )
    // },
    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];
  const primeHisCol = [
    {
      name: "S.No",
      width: "100px",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      width: "150px",
      selector: (row) => row.userID
    },
    {
      name: "Amount",
      width: "150px",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "Status",
      width: "200px",
      selector: (row) =>
        row.Status == 1 ? "Created" : row.Status == 2 ? "Approved" : "Cancel"
    },
    // {
    //   name: "Action",
    //   width: "200px",
    //   selector: (row) =>
    //     row.Status == 1 ? (
    //       <button
    //         className="primary_btn py-1 px-4"
    //         onClick={() =>
    //           cancelprimestatus(
    //             row.amount,
    //             row.nonce,
    //             row.sig,
    //             row.type,
    //             row._id
    //           )
    //         }
    //       >
    //         Cancel
    //       </button>
    //     ) : row.Status == 2 ? (
    //       ""
    //     ) : (
    //       ""
    //     )
    // },
    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];
  const BirDirHisCol = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    {
      name: "Amount",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "Status",
      selector: (row) =>
        row.Status == 1 ? "Created" : row.Status == 2 ? "Approved" : "Cancel"
    },
    // {
    //   name: "Action",
    //   selector: (row) =>
    //     row.Status == 1 ? (
    //       <button
    //         className="primary_btn py-1 px-4"
    //         onClick={() =>
    //           cancelstatusbinary(row.amount, row.nonce, row.sig, row.type)
    //         }
    //       >
    //         Cancel
    //       </button>
    //     ) : row.Status == 2 ? (
    //       "Approved"
    //     ) : (
    //       ""
    //     )
    // },
    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];
  function convertToDubaiTime(date) {
    const inputDate = new Date(date);
    const utcTime = inputDate.getTime();
    const dubaiOffset = 5400 * 1000;
    const dubaiTime = new Date(utcTime - dubaiOffset);
    return dubaiTime;
  }
  const DateDiff = (fDate) => {
    var currentDate = new Date();

    var futureDate = new Date(fDate);

    var timeDifference = futureDate - currentDate;

    var daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    console.log("Number of days left: " + daysLeft + " days");
    return daysLeft > 0 ? daysLeft : 0;
  };
  const Binarycolumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    // {
    //   name: "Amount",
    //   selector: (row) => row.amount,
    // },
    // {
    //   name: "Convert Token",
    //   selector: (row, index) => row.convertToken,
    // },
    {
      name: "Future Wallet",
      selector: (row, index) => parseFloat(row.futureWallet).toFixed(4)
    },
    {
      name: "Join Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    },
    {
      name: "Expire Date",
      selector: (row, index) =>
        moment(convertToDubaiTime(row.createdAt))
          .add(setting?.Roiperiod, "d")
          .format("MMM,Do YYYY, hh:mm A")
    },
    // {
    //   name: "Action",
    //   selector: (row) =>
    //     row.futureStatus == 1 ? (
    //       moment(row.createdAt).add(setting?.Roiperiod, "d") < moment() ? (
    //         <button
    //           onClick={() =>
    //             withdrawFuture(row._id, row.createdAt, row.futureWallet)
    //           }
    //           // disabled={true}
    //           className="primary_btn py-1 px-4"
    //         >
    //           Claim
    //         </button>
    //       ) : (
    //         <span>
    //           {DateDiff(moment(row.createdAt).add(setting?.Roiperiod, "d"))} day
    //           left to claim
    //         </span>
    //       )
    //     ) : (
    //       <>Claimed</>
    //       // setting?.Roiperiod
    //     )
    // },
    // {
    //   name: "Action",
    //   selector: (row) =>
    //     row.futureStatus == 1 ? (
    //       moment(row.createdAt).add(setting?.Roiperiod, "d") < moment() ? (
    //         <button
    //           onClick={() =>
    //             withdrawFuture(row._id, row.createdAt, row.futureWallet)
    //           }
    //           // disabled={true}
    //           className="primary_btn py-1 px-4"
    //         >
    //           Claim
    //         </button>
    //       ) : (
    //         <span>
    //           {DateDiff(moment(row.createdAt).add(setting?.Roiperiod, "d"))} day
    //           left to claim
    //         </span>
    //       )
    //     ) : (
    //       <>Claimed</>
    //       // setting?.Roiperiod
    //     )
    // },

    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];
  const amtdde = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    // {
    //   name: "Amount",
    //   selector: (row) => row.amount,
    // },
    // {
    //   name: "Convert Token",
    //   selector: (row, index) => row.convertToken,
    // },
    {
      name: "Amount",
      selector: (row, index) => parseFloat(row.amount)
    },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];

  const onHistoryChange = async () => {
    // count = 1;
    console.log(countTest, "countTest");
    // if (historyTree.length > countTest) {
    console.log(historyTree, "historyTree");
    historyTree.pop();
    if (historyTree.length == 0) {
      setSearchId(userdata?.userID);
    } else {
      setSearchId(historyTree[historyTree.length - 1]);
      console.log(searchId, "fsdsdsdd");
    }

    // setCountTest(countTest + 1);
    // }
  };

  return (
    <>
      {isLoader == true && (
        <ReactLoading type={"spokes"} color={"#000000"} className="loading" />
      )}

      <div>
        <Navbar />
        {userdata?.blackUser && (
          <h1 className="blackText">Your Blocked by admin</h1>
        )}

        <div
          className={`dashboard ${
            userdata?.blackUser == true ? "Opacity" : ""
          }`}
        >
          <div className="container">
            <div className="dashboard_box">
              <div className="head_flx">
                <h2 className="h2tag mb-0">Dashboard</h2>

                {userdata?.communitystatus == 0 ? (
                  <p className="alert alert-danger">
                    You must active community and you will get prime bonus{" "}
                  </p>
                ) : (
                  ""
                )}

                {/* {userdata.futureStatus == 1 ? (
                  <p className="alert alert-danger">
                    {" "}
                    `$
                    {DateDiff(
                      moment(userdata.createdAt).add(setting?.Roiperiod, "d")
                    )}{" "}
                    day left to claim`{" "}
                  </p>
                ) : (
                  ""
                )} */}

                <div
                  className="wallet"
                  onClick={() => CopyFunction1(Connection.address)}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    {" "}
                    Your Wallet :
                    <span> {halfAddrShow(Connection.address)}</span>
                  </span>
                  {!copystatus1 ? (
                    <img
                      src={require("../assets/images/copy.png")}
                      alt="copy"
                      className="img-fluid ms-3"
                      width="20px"
                    />
                  ) : (
                    <i
                      class="fa fa-check-circle ms-3"
                      aria-hidden="true"
                      style={{ color: "green", fontSize: "20px" }}
                    ></i>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-xxl-3">
                  <div className="box">
                    <div>
                      <p>Username </p>
                      <h5>{userdata.userName}</h5>

                      <p>My Present ID </p>
                      <h5>{userdata.userID}</h5>
                    </div>
                    <div className="icon">
                      <img
                        src={require("../assets/images/user.png")}
                        alt="user"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="box">
                    <div>
                      <h5>{userdata.originalReferrerID}</h5>
                      <p>Parent ID</p>
                    </div>
                    <div className="icon">
                      <img
                        src={require("../assets/images/refferal.png")}
                        alt="refferal"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="box">
                    <div>
                      <h5>
                        {ceilinglimit > 0
                          ? ceilinglimit / 2 < setting?.maxBinaryPercentage
                            ? (ceilinglimit / 2).toFixed(4)
                            : setting?.maxBinaryPercentage
                          : 0}

                        {/* {(
                          userdata.binaryIncome -
                          (userdata.binaryIncome * setting?.adminDeduction) /
                            100
                        ).toFixed(4) +
                          "/" +
                          userdata.treePercentage / 2} */}

                        {/* {setting?.maxBinaryPercentage > ceilinglimit
                          ? setting?.maxBinaryPercentage
                          : userdata.treePercentage / 2} */}
                      </h5>
                      <p>Ceiling Limit</p>
                    </div>
                    <div className="icon">
                      <img
                        src={require("../assets/images/limit.png")}
                        alt="limit"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="box">
                    <div>
                      <h5>{userdata.directReferalUsers}</h5>
                      <p>Direct Referral Count</p>
                    </div>
                    <div className="icon">
                      <img
                        src={require("../assets/images/refferal.png")}
                        alt="refferal"
                        className="img-fluid"
                      />
                    </div>
                  </div>

                  <div className="box">
                    <div className="w-100">
                      <p className="mb-2">Referral Link</p>
                      <div
                        className="link"
                        onClick={() =>
                          CopyFunction(
                            config.frontend + "/join-now/" + userdata.userID
                          )
                        }
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div className="half">
                          {config.frontend + "/join-now/" + userdata.userID}
                        </div>
                        <div className="ms-3">
                          {!copystatus ? (
                            <img
                              src={require("../assets/images/copy.png")}
                              alt="copy"
                              className="img-fluid ms-3"
                              width="20px"
                            />
                          ) : (
                            <i
                              class="fa fa-check-circle ms-3"
                              aria-hidden="true"
                              style={{ color: "green", fontSize: "20px" }}
                            ></i>
                          )}
                          {/* <img
                            src={require("../assets/images/copy.png")}
                            alt="copy"
                            className="img-fluid copy"
                          /> */}
                        </div>
                      </div>
                      <ul>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-x-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-telegram-plane"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-solid fa-phone"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="box">
                    <div className="w-100">
                      <p className="mb-2">User Status</p>
                      <h5>
                        {userdata?.binarystatus == 1 ? "Active" : "Inactive"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-xxl-9">
                  <div className="box_bdr">
                    <div className="row">
                      <div className="col-lg-12 col-xxl-6 d-flex">
                        <div className="box text-center">
                          <div>
                            <p className="mb-3">Token Value</p>
                            <h5 className="mb-0">
                              {Number(userdata?.tokenType) == 1 ? (
                                <>
                                  {" "}
                                  {1} USD ={" "}
                                  {(1 / tokenpricevaluextrx).toFixed(4)} XTAURAS
                                </>
                              ) : (
                                <>
                                  {1} USD = {(1 / tokenpricevalue).toFixed(4)}{" "}
                                  TAURAS
                                </>
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xxl-6 d-flex">
                        <div className="box text-center">
                          <div className="w-100">
                            <p className="text-center mb-3">ROI Balance</p>
                            {console.log(
                              userdata.roiIncome,
                              setting?.adminDeduction,
                              userdata.totalWithdrawRoi,
                              "rfesfvrvfr",
                              userdata.roiIncome -
                                (userdata.roiIncome * setting?.adminDeduction) /
                                  100 -
                                userdata.totalWithdrawRoi
                            )}
                            <h5 className="text-center mb-0">
                              {(
                                userdata.roiIncome -
                                (userdata.roiIncome * setting?.adminDeduction) /
                                  100 -
                                userdata.totalWithdrawRoi
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                            <span>
                              {Number(userdata?.tokenType) == 1 ? (
                                <>
                                  (
                                  {(
                                    ((userdata.roiIncome -
                                      (userdata.roiIncome *
                                        setting?.adminDeduction) /
                                        100 -
                                      userdata.totalWithdrawRoi) *
                                      1) /
                                    tokenpricevaluextrx
                                  ).toFixed(4)}{" "}
                                  XTAURAS)
                                </>
                              ) : (
                                <>
                                  (
                                  {(
                                    ((userdata.roiIncome -
                                      (userdata.roiIncome *
                                        setting?.adminDeduction) /
                                        100 -
                                      userdata.totalWithdrawRoi) *
                                      1) /
                                    tokenpricevalue
                                  ).toFixed(4)}{" "}
                                  TAURAS)
                                </>
                              )}
                            </span>
                            {/* {userdata.roiIncome -
                              (userdata.roiIncome * setting?.adminDeduction) /
                                100 -
                              userdata.totalWithdrawRoi >
                              0 && (
                              <button
                                className="primary_btn text-center"
                                // disabled={true}
                                style={{ margin: "10px auto", display: "flex" }}
                                onClick={withdrawRoiUser}
                              >
                                Withdraw
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>
                      {/* //Prime */}
                      {/* <div className="col-lg-12 col-xxl-4 d-flex">
                        <div className="box text-center">
                          <div className="w-100">
                            <p className="text-center mb-3">Prime Bonus</p>

                            <h5 className="text-center mb-0">
                              {(
                                userdata.primeIncome -
                                (userdata.primeIncome *
                                  setting?.adminDeduction) /
                                  100 -
                                userdata.totalWithdrawPrime
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                            <span>
                              {Number(userdata?.tokenType) == 1 ? (
                                <>
                                  (
                                  {(
                                    ((userdata.primeIncome -
                                      (userdata.primeIncome *
                                        setting?.adminDeduction) /
                                        100 -
                                      userdata.totalWithdrawPrime) *
                                      1) /
                                    tokenpricevaluextrx
                                  ).toFixed(4)}{" "}
                                  XTAURAS)
                                </>
                              ) : (
                                <>
                                  (
                                  {(
                                    ((userdata.primeIncome -
                                      (userdata.primeIncome *
                                        setting?.adminDeduction) /
                                        100 -
                                      userdata.totalWithdrawPrime) *
                                      1) /
                                    tokenpricevalue
                                  ).toFixed(4)}{" "}
                                  TAURAS)
                                </>
                              )}
                            </span>
                          
                          </div>
                        </div>
                      </div> */}
                      {/* Prime */}
                      <div className="col-lg-12 col-xxl-6 d-flex">
                        <div className="box text-center">
                          <div>
                            <p className="mb-3">Available Balance</p>
                            {console.log(
                              userdata.directIncome,
                              userdata.binaryIncome,
                              userdata.primeIncome,
                              "aaaaaaaaaaaddddddd",
                              userdata.totalWithdraw
                            )}
                            <h5 className="mb-0">
                              {(
                                userdata.directIncome +
                                userdata.binaryIncome +
                                userdata.primeIncome -
                                ((userdata.directIncome +
                                  userdata.binaryIncome +
                                  userdata.primeIncome) *
                                  (setting?.adminDeduction +
                                    setting?.userRentalPer)) /
                                  100 -
                                (userdata.totalWithdraw +
                                  userdata.companyAmountDeducted)
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                            <span>
                              {Number(userdata?.tokenType) == 1 ? (
                                <>
                                  (
                                  {(
                                    ((userdata.directIncome +
                                      userdata.binaryIncome +
                                      userdata.primeIncome -
                                      ((userdata.directIncome +
                                        userdata.binaryIncome +
                                        userdata.primeIncome) *
                                        (setting?.adminDeduction +
                                          setting?.userRentalPer)) /
                                        100 -
                                      (userdata.totalWithdraw +
                                        userdata.companyAmountDeducted)) *
                                      1) /
                                    tokenpricevaluextrx
                                  ).toFixed(4)}{" "}
                                  XTAURAS)
                                </>
                              ) : (
                                <>
                                  (
                                  {(
                                    ((userdata.directIncome +
                                      userdata.binaryIncome +
                                      userdata.primeIncome -
                                      ((userdata.directIncome +
                                        userdata.binaryIncome +
                                        userdata.primeIncome) *
                                        (setting?.adminDeduction +
                                          setting?.userRentalPer)) /
                                        100 -
                                      (userdata.totalWithdraw +
                                        userdata.companyAmountDeducted)) *
                                      1) /
                                    tokenpricevalue
                                  ).toFixed(4)}{" "}
                                  TAURAS)
                                </>
                              )}
                            </span>
                            {/* {userdata.directIncome +
                              userdata.binaryIncome +
                              userdata.primeIncome -
                              ((userdata.directIncome +
                                userdata.binaryIncome +
                                userdata.primeIncome) *
                                (setting?.adminDeduction +
                                  setting?.userRentalPer)) /
                                100 -
                              (userdata.totalWithdraw +
                                userdata.companyAmountDeducted) >
                              0 && (
                              <button
                                className="primary_btn text-center"
                                // disabled={true}
                                style={{ margin: "10px auto", display: "flex" }}
                                onClick={withdrawBalance}
                              >
                                Withdraw
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xxl-6 d-flex">
                        <div className="box text-center">
                          <div>
                            <p className="mb-3">Withdrawal Balance</p>
                            <h5 className="mb-0">
                              {/* {withBal
                                ? (
                                    withBal -
                                    (withBal * setting?.adminDeduction) / 100
                                  ).toFixed(4)
                                : 0}{" "} */}
                              {(
                                userdata?.totalWithdraw +
                                userdata?.totalWithdrawRoi +
                                userdata?.totalWithdrawfutureWallet
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box totalbox">
                      <div className="w-100">
                        <p className="text-center mb-3">Total Earned Amount</p>
                        <div className="d-flex align-items-center mb-1">
                          <div class="progress w-100">
                            <div
                              class="progress-bar progress-bar-striped"
                              role="progressbar"
                              aria-label="Default striped example"
                              style={{
                                width: `${Math.round(
                                  ((userdata.roiIncome +
                                    userdata.binaryIncome +
                                    userdata?.directIncome -
                                    ((userdata.roiIncome +
                                      userdata.binaryIncome +
                                      userdata?.directIncome) *
                                      setting?.adminDeduction) /
                                      100) /
                                    (userdata.depositAmountinBinary *
                                      setting?.maxAmountreceivedLimit -
                                      (userdata.depositAmountinBinary *
                                        setting?.maxAmountreceivedLimit *
                                        setting?.adminDeduction) /
                                        100)) *
                                    100
                                    ? ((userdata.roiIncome +
                                        userdata.binaryIncome +
                                        userdata.directIncome -
                                        ((userdata.roiIncome +
                                          userdata.binaryIncome +
                                          userdata.directIncome) *
                                          setting?.adminDeduction) /
                                          100) /
                                        (userdata.treePercentage *
                                          setting?.maxAmountreceivedLimit -
                                          (userdata.treePercentage *
                                            setting?.maxAmountreceivedLimit *
                                            setting?.adminDeduction) /
                                            100)) *
                                        100
                                    : 0
                                )}%`
                              }}
                              aria-valuenow="1"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <h6 className="ms-2">
                            {/* {earnedpercatge ? earnedpercatge : 0}% */}
                            {(
                              ((userdata?.roiIncome +
                                userdata?.binaryIncome +
                                userdata?.directIncome -
                                ((userdata?.roiIncome +
                                  userdata?.binaryIncome +
                                  userdata?.directIncome) *
                                  setting?.adminDeduction) /
                                  100) /
                                (userdata?.treePercentage *
                                  setting?.maxAmountreceivedLimit -
                                  (userdata?.treePercentage *
                                    setting?.maxAmountreceivedLimit *
                                    setting?.adminDeduction) /
                                    100)) *
                              100
                            ).toFixed(1) + " %"}{" "}
                          </h6>
                        </div>
                        <div className="flx">
                          <span>
                            Progress towards earned income reaching{" "}
                            {setting?.maxAmountreceivedLimit}x of your Total
                            Investment
                          </span>
                          <span className="bold me-5 pe-2 w-25">
                            {(
                              userdata.roiIncome +
                              userdata.binaryIncome +
                              userdata?.directIncome -
                              ((userdata.roiIncome +
                                userdata.binaryIncome +
                                userdata.directIncome) *
                                setting?.adminDeduction) /
                                100
                            ).toFixed(4)}{" "}
                            USD /{" "}
                            {userdata.treePercentage *
                              setting?.maxAmountreceivedLimit -
                              (userdata.treePercentage *
                                setting?.maxAmountreceivedLimit *
                                setting?.adminDeduction) /
                                100}{" "}
                            USD
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="banner_btn_grp dash_btns">
                      {userdata?.blackUser == false && (
                        <button
                          className="primary_btn primary_btn_flx"
                          // className="primary_btn"
                          data-bs-toggle="modal"
                          // disabled={true}
                          data-bs-target="#Retopup-binary"
                        >
                          Retopup
                          <span className="primary_btn_arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 10 10"
                              fill="none"
                            >
                              <path
                                d="M9.66455 4.63121L6.19177 1.14606C6.09264 1.04658 5.96051 0.991974 5.81963 0.991974C5.67859 0.991974 5.54655 1.04666 5.44741 1.14606L5.13211 1.46256C5.03306 1.56189 4.97849 1.69456 4.97849 1.83602C4.97849 1.9774 5.03306 2.11454 5.13211 2.21387L7.15808 4.25148H0.721475C0.431269 4.25148 0.201965 4.47947 0.201965 4.77079V5.21823C0.201965 5.50954 0.431269 5.76052 0.721475 5.76052H7.18107L5.13219 7.80951C5.03313 7.909 4.97856 8.03806 4.97856 8.17952C4.97856 8.32082 5.03313 8.45176 5.13219 8.55117L5.44749 8.86664C5.54662 8.96613 5.67867 9.02034 5.81971 9.02034C5.96059 9.02034 6.09271 8.96542 6.19185 8.86594L9.66463 5.38087C9.764 5.28107 9.81864 5.14785 9.81825 5.00624C9.81857 4.86415 9.764 4.73085 9.66455 4.63121Z"
                                fill="#041E37"
                              />
                            </svg>
                          </span>
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="box_bdr text-center pb-2">
                    <div className="row">
                      <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Total Investment</p>
                            <h5> {userdata.treePercentage?.toFixed(4)} USD</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Future Wallet Balance</p>
                            <h5>
                              {(
                                userdata.futureWallet -
                                userdata.totalWithdrawfutureWallet
                              ).toFixed(4)}{" "}
                              {Number(userdata?.tokenType) == 0
                                ? "TAURAS"
                                : "XTAURAS"}
                            </h5>
                            {/* </h5>
                            <span>
                              (
                              {userdata.futureWallet -
                                userdata.totalWithdrawfutureWallet}{" "}
                              USD)
                            </span> */}
                            {/* <button className="primary_btn text-center" style={{margin:'10px auto',display:'flex'}} onClick={withdrawRoiUser}>Withdraw</button> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Matching Bonus</p>
                            <h5>
                              {(
                                userdata.binaryIncome -
                                (userdata.binaryIncome *
                                  (setting?.adminDeduction +
                                    setting?.userRentalPer)) /
                                  100
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Referral Bonus</p>
                            <h5>
                              {(
                                userdata.directIncome -
                                (userdata.directIncome *
                                  (setting?.adminDeduction +
                                    setting?.userRentalPer)) /
                                  100
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>User Deduction Amount</p>
                            <h5>
                              {userdata?.companyAmountDeducted?.toFixed(4)} USD
                            </h5>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-xxl-4 d-flex">
                        <div className="box">
                          <div>
                            <p>Prime Bonus</p>
                            <h5>
                              {(
                                userdata.primeIncome -
                                (userdata.primeIncome *
                                  (setting?.adminDeduction +
                                    setting?.userRentalPer)) /
                                  100
                              ).toFixed(4)}{" "}
                              USD
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      ROI Tree
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="box_flx">
                        <button
                          onClick={() => setSearchId(userdata?.userID)}
                          className="primary_btn"
                        >
                          Home
                        </button>
                        <div class="input-group mb-3">
                          {console.log(searchId, "efcswefr")}
                          <input
                            type="text"
                            class="form-control pe-1"
                            placeholder="Search ID"
                            onChange={searchchange}
                            value={searchId ? searchId : ""}
                          />
                          <span class="input-group-text" id="basic-addon2">
                            <img
                              src={search}
                              className="img-fluid"
                              alt="Icon"
                            />
                          </span>
                        </div>
                        <button
                          onClick={onHistoryChange}
                          className="primary_btn"
                        >
                          Back
                        </button>
                      </div>
                      <Scrollbars style={{ width: "100%", height: 570 }}>
                        <>
                          {searchId != "" ? (
                            <>
                              {binaryTree.map((ele, i) => {
                                console.log(ele, "eliii");
                                if (ele.items.key === searchId)
                                  return (
                                    <div className="plan_pool">
                                      <div className="plan_pool_head">
                                        <img
                                          src={user1}
                                          className="img-fluid"
                                          alt="Icon"
                                          data-tooltip-id="my-tooltip-2"
                                        />
                                        {userdata?.userID == parseInt(searchId)}{" "}
                                        <span> ID {searchId} </span>
                                        <ReactTooltip
                                          id="my-tooltip-2"
                                          place="bottom"
                                          variant="info"
                                          content={
                                            <div className="tooltipCard">
                                              <h4>{ele.docs_[0]?.userID}</h4>
                                              <ul>
                                                <li>
                                                  <label>Username</label>{" "}
                                                  <span>
                                                    {ele.docs_[0]?.userName}
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>
                                                    Total Inverstment
                                                  </label>{" "}
                                                  <span>
                                                    $
                                                    {
                                                      ele.docs_[0]
                                                        ?.depositAmountinBinary
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Carry Left</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllLeftbalance
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Carry Right</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllRightbalance
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Total Left</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllLeftUser
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Total Right</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllRightUser
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Left Volume</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllLeftbalance1
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Right Volume</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.overAllRightbalance1
                                                    }
                                                  </span>
                                                </li>
                                                <li>
                                                  <label>Referral Count</label>{" "}
                                                  <span>
                                                    {
                                                      ele.docs_[0]
                                                        ?.directReferalUsers
                                                    }
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          }
                                        />
                                      </div>
                                      {/* First Row */}
                                      {binaryTree.map((ele, i) => {
                                        i = i + 1;
                                        console.log(ele, "ele");
                                        if (
                                          ele.items.key === parseInt(searchId)
                                        )
                                          return (
                                            <>
                                              <span style={{ display: "none" }}>
                                                {
                                                  (userLevleft =
                                                    ele.items?.left)
                                                }{" "}
                                                {
                                                  (userLevright =
                                                    ele.items?.right)
                                                }
                                              </span>
                                              <div className="plan_pool_first_row">
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-3"
                                                >
                                                  <img
                                                    src={user2}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-3"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-4"
                                                >
                                                  <img
                                                    src={user2}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-4"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                      })}
                                      {/* Second Row */}
                                      <div className="plan_pool_second_row">
                                        {binaryTree.map((ele, i) => {
                                          if (ele.items.key === userLevleft)
                                            return (
                                              <>
                                                <span
                                                  style={{ display: "none" }}
                                                >
                                                  {
                                                    (userLevtTwoleft =
                                                      ele.items?.left)
                                                  }{" "}
                                                  {
                                                    (userLevTworight =
                                                      ele.items?.right)
                                                  }
                                                </span>
                                                <div>
                                                  <div
                                                    className="plan_pool_head_child"
                                                    data-tooltip-id="my-tooltip-5"
                                                  >
                                                    <img
                                                      src={user3}
                                                      onClick={() => {
                                                        setSearchId(
                                                          ele.items?.left
                                                        );
                                                        setHistoryTree(
                                                          (prev) => [
                                                            ...prev,
                                                            ele.items?.left
                                                          ]
                                                        );
                                                      }}
                                                      className={`img-fluid ${
                                                        ele.items?.left == 0
                                                          ? "blurr"
                                                          : ""
                                                      }`}
                                                      alt="Icon"
                                                    />
                                                    <span>
                                                      ID {ele.items?.left}
                                                    </span>
                                                    {ele.items?.left != 0 && (
                                                      <ReactTooltip
                                                        id="my-tooltip-5"
                                                        place="bottom"
                                                        variant="info"
                                                        content={
                                                          <div className="tooltipCard">
                                                            <h4>
                                                              {
                                                                ele
                                                                  .docs_left?.[0]
                                                                  ?.userID
                                                              }
                                                            </h4>
                                                            <ul>
                                                              <li>
                                                                <label>
                                                                  Username
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.userName
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total
                                                                  Inverstment
                                                                </label>{" "}
                                                                <span>
                                                                  $
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.depositAmountinBinary
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Carry Left
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllLeftbalance
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Carry Right
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllRightbalance
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total Left
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllLeftUser
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total Right
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllRightUser
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Left Volume
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllLeftbalance1
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Right Volume
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.overAllRightbalance1
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Referral Count
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_left?.[0]
                                                                      ?.directReferalUsers
                                                                  }
                                                                </span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div
                                                    className="plan_pool_head_child"
                                                    data-tooltip-id="my-tooltip-6"
                                                  >
                                                    <img
                                                      src={user3}
                                                      onClick={() => {
                                                        setSearchId(
                                                          ele.items?.right
                                                        );
                                                        setHistoryTree(
                                                          (prev) => [
                                                            ...prev,
                                                            ele.items?.right
                                                          ]
                                                        );
                                                      }}
                                                      className={`img-fluid ${
                                                        ele.items?.right == 0
                                                          ? "blurr"
                                                          : ""
                                                      }`}
                                                      alt="Icon"
                                                    />
                                                    <span>
                                                      ID {ele.items?.right}
                                                    </span>
                                                    {ele.items?.right != 0 && (
                                                      <ReactTooltip
                                                        id="my-tooltip-6"
                                                        place="bottom"
                                                        variant="info"
                                                        content={
                                                          <div className="tooltipCard">
                                                            <h4>
                                                              {
                                                                ele
                                                                  .docs_right?.[0]
                                                                  ?.userID
                                                              }
                                                            </h4>
                                                            <ul>
                                                              <li>
                                                                <label>
                                                                  Username
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.userName
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total
                                                                  Inverstment
                                                                </label>{" "}
                                                                <span>
                                                                  $
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.depositAmountinBinary
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Carry Left
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllLeftbalance
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Carry Right
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllRightbalance
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total Left
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllLeftUser
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Total Right
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllRightUser
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Left Volume
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllLeftbalance1
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Right Volume
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.overAllRightbalance1
                                                                  }
                                                                </span>
                                                              </li>
                                                              <li>
                                                                <label>
                                                                  Referral Count
                                                                </label>{" "}
                                                                <span>
                                                                  {
                                                                    ele
                                                                      .docs_right?.[0]
                                                                      ?.directReferalUsers
                                                                  }
                                                                </span>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              </>
                                            );
                                        })}
                                        {binaryTree.map((ele, i) => {
                                          if (ele.items.key === userLevright)
                                            return (
                                              <div>
                                                <span
                                                  style={{ display: "none" }}
                                                >
                                                  {
                                                    (userLevtThreeleft =
                                                      ele.items?.left)
                                                  }{" "}
                                                  {
                                                    (userLevThreeright =
                                                      ele.items?.right)
                                                  }
                                                </span>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-7"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-7"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-8"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-8"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}
                                      </div>

                                      {/* third Row */}
                                      <div className="plan_pool_third_row">
                                        {binaryTree.map((ele, i) => {
                                          if (ele.items.key === userLevtTwoleft)
                                            return (
                                              <div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-9"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-9"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-10"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-10"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}

                                        {binaryTree.map((ele, i) => {
                                          if (ele.items.key === userLevTworight)
                                            return (
                                              <div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-11"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-11"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-12"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-12"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}

                                        {binaryTree.map((ele, i) => {
                                          if (
                                            ele.items.key === userLevtThreeleft
                                          )
                                            return (
                                              <div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-13"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-13"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-14"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-14"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}

                                        {binaryTree.map((ele, i) => {
                                          if (
                                            ele.items.key === userLevThreeright
                                          )
                                            return (
                                              <div>
                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-15"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.left
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.left
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.left == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.left}
                                                  </span>
                                                  {ele.items?.left != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-15"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_left?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className="plan_pool_head_child"
                                                  data-tooltip-id="my-tooltip-16"
                                                >
                                                  <img
                                                    src={user3}
                                                    onClick={() => {
                                                      setSearchId(
                                                        ele.items?.right
                                                      );
                                                      setHistoryTree((prev) => [
                                                        ...prev,
                                                        ele.items?.right
                                                      ]);
                                                    }}
                                                    className={`img-fluid ${
                                                      ele.items?.right == 0
                                                        ? "blurr"
                                                        : ""
                                                    }`}
                                                    alt="Icon"
                                                  />
                                                  <span>
                                                    ID {ele.items?.right}
                                                  </span>
                                                  {ele.items?.right != 0 && (
                                                    <ReactTooltip
                                                      id="my-tooltip-16"
                                                      place="bottom"
                                                      variant="info"
                                                      content={
                                                        <div className="tooltipCard">
                                                          <h4>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userID
                                                            }
                                                          </h4>
                                                          <ul>
                                                            <li>
                                                              <label>
                                                                Username
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.userName
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total
                                                                Inverstment
                                                              </label>{" "}
                                                              <span>
                                                                $
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.depositAmountinBinary
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Carry Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Left
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Total Right
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightUser
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Left Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllLeftbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Right Volume
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.overAllRightbalance1
                                                                }
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <label>
                                                                Referral Count
                                                              </label>{" "}
                                                              <span>
                                                                {
                                                                  ele
                                                                    .docs_right?.[0]
                                                                    ?.directReferalUsers
                                                                }
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                        })}
                                      </div>
                                    </div>
                                  );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="plan_pool">
                                <div className="plan_pool_head">
                                  <img
                                    src={user1}
                                    className="img-fluid"
                                    alt="Icon"
                                    data-tooltip-id="my-tooltip-2"
                                  />
                                  {userdata?.userID == parseInt(searchId)}{" "}
                                  <span>ID {userdata.userID} </span>
                                  <ReactTooltip
                                    id="my-tooltip-2"
                                    place="bottom"
                                    variant="info"
                                    content={
                                      <div className="tooltipCard">
                                        <h4>{userdata.userID}</h4>
                                        <ul>
                                          <li>
                                            <label>Total Inverstment</label>{" "}
                                            <span>
                                              ${userdata.depositAmountinBinary}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Carry Left</label>{" "}
                                            <span>
                                              {userdata.overAllLeftbalance}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Carry Right</label>{" "}
                                            <span>
                                              {userdata.overAllRightbalance}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Total Left</label>{" "}
                                            <span>
                                              {userdata.overAllLeftUser}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Total Right</label>{" "}
                                            <span>
                                              {userdata.overAllRightUser}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Left Volume</label>{" "}
                                            <span>
                                              {userdata.overAllLeftbalance1}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Right Volume</label>{" "}
                                            <span>
                                              {userdata.overAllRightbalance1}
                                            </span>
                                          </li>
                                          <li>
                                            <label>Referral Count</label>{" "}
                                            <span>
                                              {userdata.directReferalUsers}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    }
                                  />
                                </div>
                                {/* First Row */}

                                {binaryTree.map((ele, i) => {
                                  i = i + 1;
                                  console.log(ele, "ele");
                                  if (
                                    ele.items.key === parseInt(userdata.userID)
                                  )
                                    return (
                                      <>
                                        <span style={{ display: "none" }}>
                                          {(userLevleft = ele.items?.left)}{" "}
                                          {(userLevright = ele.items?.right)}
                                        </span>
                                        <div className="plan_pool_first_row">
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-17"
                                          >
                                            <img
                                              src={user2}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-17"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-18"
                                          >
                                            <img
                                              src={user2}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-18"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    );
                                })}
                                {/* Second Row */}

                                <div className="plan_pool_second_row">
                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevleft)
                                      return (
                                        <>
                                          <span style={{ display: "none" }}>
                                            {
                                              (userLevtTwoleft =
                                                ele.items?.left)
                                            }{" "}
                                            {
                                              (userLevTworight =
                                                ele.items?.right)
                                            }
                                          </span>
                                          <div>
                                            <div
                                              className="plan_pool_head_child"
                                              data-tooltip-id="my-tooltip-19"
                                            >
                                              <img
                                                src={user3}
                                                onClick={() => {
                                                  setSearchId(ele.items?.left);
                                                  setHistoryTree((prev) => [
                                                    ...prev,
                                                    ele.items?.left
                                                  ]);
                                                }}
                                                className={`img-fluid ${
                                                  ele.items?.left == 0
                                                    ? "blurr"
                                                    : ""
                                                }`}
                                                alt="Icon"
                                              />
                                              <span>ID {ele.items?.left}</span>
                                              {ele.items?.left != 0 && (
                                                <ReactTooltip
                                                  id="my-tooltip-19"
                                                  place="bottom"
                                                  variant="info"
                                                  content={
                                                    <div className="tooltipCard">
                                                      <h4>
                                                        {
                                                          ele.docs_left?.[0]
                                                            ?.userID
                                                        }
                                                      </h4>
                                                      <ul>
                                                        <li>
                                                          <label>
                                                            Username
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.userName
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Inverstment
                                                          </label>{" "}
                                                          <span>
                                                            $
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.depositAmountinBinary
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Carry Left
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllLeftbalance
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Carry Right
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllRightbalance
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Left
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllLeftUser
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Right
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllRightUser
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Left Volume
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllLeftbalance1
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Right Volume
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.overAllRightbalance1
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Referral Count
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele.docs_left?.[0]
                                                                ?.directReferalUsers
                                                            }
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  }
                                                />
                                              )}
                                            </div>
                                            <div
                                              className="plan_pool_head_child"
                                              data-tooltip-id="my-tooltip-20"
                                            >
                                              <img
                                                src={user3}
                                                onClick={() => {
                                                  setSearchId(ele.items?.right);
                                                  setHistoryTree((prev) => [
                                                    ...prev,
                                                    ele.items?.right
                                                  ]);
                                                }}
                                                className={`img-fluid ${
                                                  ele.items?.right == 0
                                                    ? "blurr"
                                                    : ""
                                                }`}
                                                alt="Icon"
                                              />
                                              <span>ID {ele.items?.right}</span>
                                              {ele.items?.right != 0 && (
                                                <ReactTooltip
                                                  id="my-tooltip-20"
                                                  place="bottom"
                                                  variant="info"
                                                  content={
                                                    <div className="tooltipCard">
                                                      <h4>
                                                        {
                                                          ele.docs_right?.[0]
                                                            ?.userID
                                                        }
                                                      </h4>
                                                      <ul>
                                                        <li>
                                                          <label>
                                                            Username
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.userName
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Inverstment
                                                          </label>{" "}
                                                          <span>
                                                            $
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.depositAmountinBinary
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Carry Left
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllLeftbalance
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Carry Right
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllRightbalance
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Left
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllLeftUser
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Total Right
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllRightUser
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Left Volume
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllLeftbalance1
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Right Volume
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.overAllRightbalance1
                                                            }
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <label>
                                                            Referral Count
                                                          </label>{" "}
                                                          <span>
                                                            {
                                                              ele
                                                                .docs_right?.[0]
                                                                ?.directReferalUsers
                                                            }
                                                          </span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      );
                                  })}
                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevright)
                                      return (
                                        <div>
                                          <span style={{ display: "none" }}>
                                            {
                                              (userLevtThreeleft =
                                                ele.items?.left)
                                            }{" "}
                                            {
                                              (userLevThreeright =
                                                ele.items?.right)
                                            }
                                          </span>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-21"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-21"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-22"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-22"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  })}
                                </div>

                                {/* third Row */}
                                <div className="plan_pool_third_row">
                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevtTwoleft)
                                      return (
                                        <div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-23"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-23"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>

                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-24"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-24"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  })}

                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevTworight)
                                      return (
                                        <div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-25"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-25"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>

                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-26"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-26"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  })}

                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevtThreeleft)
                                      return (
                                        <div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-27"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-27"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>

                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-28"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-28"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  })}

                                  {binaryTree.map((ele, i) => {
                                    if (ele.items.key === userLevThreeright)
                                      return (
                                        <div>
                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-29"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.left);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.left
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.left == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.left}</span>
                                            {ele.items?.left != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-29"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_left?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_left?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>

                                          <div
                                            className="plan_pool_head_child"
                                            data-tooltip-id="my-tooltip-30"
                                          >
                                            <img
                                              src={user3}
                                              onClick={() => {
                                                setSearchId(ele.items?.right);
                                                setHistoryTree((prev) => [
                                                  ...prev,
                                                  ele.items?.right
                                                ]);
                                              }}
                                              className={`img-fluid ${
                                                ele.items?.right == 0
                                                  ? "blurr"
                                                  : ""
                                              }`}
                                              alt="Icon"
                                            />
                                            <span>ID {ele.items?.right}</span>
                                            {ele.items?.right != 0 && (
                                              <ReactTooltip
                                                id="my-tooltip-30"
                                                place="bottom"
                                                variant="info"
                                                content={
                                                  <div className="tooltipCard">
                                                    <h4>
                                                      {
                                                        ele.docs_right?.[0]
                                                          ?.userID
                                                      }
                                                    </h4>
                                                    <ul>
                                                      <li>
                                                        <label>Username</label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.userName
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Inverstment
                                                        </label>{" "}
                                                        <span>
                                                          $
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.depositAmountinBinary
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Carry Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Left
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Total Right
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightUser
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Left Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllLeftbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Right Volume
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.overAllRightbalance1
                                                          }
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <label>
                                                          Referral Count
                                                        </label>{" "}
                                                        <span>
                                                          {
                                                            ele.docs_right?.[0]
                                                              ?.directReferalUsers
                                                          }
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      </Scrollbars>
                      {searchId == userdata?.userID && (
                        <>
                          {searchId && searchId != "" ? (
                            <>
                              {binaryTree.map((ele, i) => {
                                if (ele.items.key === searchId)
                                  return (
                                    <div className="blw_box">
                                      <div>
                                        <p>Left Current Volume : </p>
                                        <h5>
                                          {ele.docs_[0]?.overAllLeftbalance2
                                            ? ele.docs_[0]?.overAllLeftbalance2
                                            : 0}{" "}
                                          USD
                                        </h5>
                                      </div>
                                      <div>
                                        <p>Right Current Volume : </p>
                                        <h5>
                                          {ele.docs_[0]?.overAllRightbalance2
                                            ? ele.docs_[0]?.overAllRightbalance2
                                            : 0}{" "}
                                          USD
                                        </h5>
                                      </div>
                                    </div>
                                  );
                              })}
                            </>
                          ) : (
                            <div className="blw_box">
                              <div>
                                <p>Left Current Volume : </p>
                                <h5>
                                  {userdata?.overAllLeftbalance1
                                    ? userdata?.overAllLeftbalance1
                                    : 0}{" "}
                                  USD
                                </h5>
                              </div>
                              <div>
                                <p>Right Current Volume : </p>
                                <h5>
                                  {" "}
                                  {userdata?.overAllRightbalance1
                                    ? userdata?.overAllRightbalance1
                                    : 0}{" "}
                                  USD
                                </h5>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Investment History
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={columns}
                          data={invesmentData}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Referral History
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={columnsreferral}
                          data={referraldata}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseseven"
                      aria-expanded="false"
                      aria-controls="collapseseven"
                    >
                      Referral Bonus
                    </button>
                  </h2>
                  <div
                    id="collapseseven"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample7"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={RefBonCol}
                          data={refBon}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      ROI Bonus
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={ROIcolumns}
                          data={roidata}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeleven"
                      aria-expanded="false"
                      aria-controls="collapseeleven"
                    >
                      Matching History
                    </button>
                  </h2>
                  <div
                    id="collapseeleven"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={Matchingcolumns}
                          data={matchingHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Prime */}

              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive1"
                      aria-expanded="false"
                      aria-controls="collapsefive1"
                    >
                      Prime Bonus History
                    </button>
                  </h2>
                  <div
                    id="collapsefive1"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={Primecolumns}
                          data={primeHist}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Prime */}
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Future Wallet History
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={Binarycolumns}
                          data={binaryhistory}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefinally"
                      aria-expanded="false"
                      aria-controls="collapsefinally"
                    >
                      Amount Deduction History
                    </button>
                  </h2>
                  <div
                    id="collapsefinally"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={amtdde}
                          data={amountdd}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* New Withdraws */}
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseten"
                      aria-expanded="false"
                      aria-controls="collapseten"
                    >
                      Withdraw History
                    </button>
                  </h2>
                  <div
                    id="collapseten"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={BirDirHisCol}
                          data={BirDirHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      ROI Withdraw History
                    </button>
                  </h2>
                  <div
                    id="collapseeight"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={roiHisCol}
                          data={roiHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsenine"
                      aria-expanded="false"
                      aria-controls="collapsenine"
                    >
                      Future Wallet Withdraw History
                    </button>
                  </h2>
                  <div
                    id="collapsenine"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={futureHisCol}
                          data={futureHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Prime */}
              {/* <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseethana"
                      aria-expanded="false"
                      aria-controls="collapseethana"
                    >
                      Prime Bonus Withdraw History
                    </button>
                  </h2>
                  <div
                    id="collapseethana"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={primeHisCol}
                          data={primeHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
        <div
          className="modal fade primary_modal"
          id="Retopup-binary"
          tabIndex={-1}
          aria-labelledby="Retopup-binaryModalLabel"
          aria-hidden="true"
          // data-bs-dismiss="modal"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="Retopup-binaryModalLabel">
                  Re-Topup
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onhandleclose}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="m-2 mb-3">
                    <label for="Referralid" class="form-label">
                      Amount in USD
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="amount"
                      value={amount}
                      id="amount"
                      // aria-describedby="amount"
                      onInput={validPositive}
                      placeholder="Amount"
                      onChange={(e) => setamount(e.target.value)}
                    />
                    <div class="mb-3 mt-3">
                      <label for="Referralid" class="form-label">
                        Amount in{" "}
                        {Number(userdata?.tokenType) == 1
                          ? "XTAURAS"
                          : "TAURAS"}
                      </label>
                      <br />
                      <input
                        type="text"
                        class="form-control"
                        name="amount"
                        value={
                          Number(userdata?.tokenType) == 0
                            ? isNaN(
                                Math.round(
                                  Number(amount) * (1 / tokenpricevalue)
                                )
                              )
                              ? 0
                              : Math.round(
                                  Number(amount) * (1 / tokenpricevalue)
                                )
                            : isNaN(
                                Math.round(
                                  Number(amount) * (1 / tokenpricevaluextrx)
                                )
                              )
                            ? 0
                            : Math.round(
                                Number(amount) * (1 / tokenpricevaluextrx)
                              )
                        }
                        id="amount"
                        aria-describedby="amount"
                        onInput={validPositive}
                        placeholder="USD Amount"
                      />
                    </div>
                    <div class="my-3">
                      <button className="primary_btn" onClick={Retopupbinary}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
