import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import user3 from "../assets/images/user_3.svg";
import user1 from "../assets/images/user_1.svg";
import user2 from "../assets/images/user_2.svg";
import india from "../assets/images/india.svg";
import uk from "../assets/images/uk.svg";
import search from "../assets/images/search.svg";
import minus1 from "../assets/images/minus.svg";
import { Scrollbars } from "react-custom-scrollbars";
import Tree from "react-animated-tree-v2";
import { plus, minus, close } from "../components/icons.js";
import { connection } from "../helper/connection.js";
import { useSelector } from "react-redux";
import config from "../lib/config";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import XTRCABI from "../Abi/XTrcABI.json";
import ReactLoading from "react-loading";

import axios from "axios";
import {
  userRegCommunity,
  getUserDetails,
  userRetopupCommunity,
  seaarchUserTree,
  get_communityhistory,
  getsettings,
  getusesettings,
  getSignatureCommu,
  updateStatus,
  cancelStatusCommu,
  getWithdrawHis
} from "../Action/ApiAction.js";

import IcoContractAbi from "../Abi/IcoContractAbi.json";
import BusdContractAbi from "../Abi/BusdContractAbi.json";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";
import moment from "moment";

let toasterOption = config.toasterOption;

export default function Dashboard_community(props) {
  const CustomizedIconTree = (props) => (
    <Tree
      icons={{ plusIcon: plus, minusIcon: minus, closeIcon: close }}
      {...props}
    />
  );
  var navigate = useNavigate();

  const walletConnection = useSelector((state) => state.walletConnection);
  const [copystatus, setcopystatus] = useState(false);
  const [copystatus1, setcopystatus1] = useState(false);
  const [ip, setIP] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const [srchLR, setSrchLR] = useState(false);
  const [seting, setSetings] = useState("");
  const [userseting, setUserSetings] = useState("");
  const [communityTree, setcommunityTree] = useState([]);
  const [amount, setamount] = useState();
  const [Communityhistory, setCommunityhistory] = useState([]);
  const [Communityhistory1, setCommunityhistory1] = useState([]);
  const [tokenpricevalue, settokenpricevalue] = useState();
  const [tokenpricevaluextrc, settokenpricevaluextrc] = useState();
  const [commuHis, setComHis] = useState([]);
  const [searchId, setSearchId] = useState(userdata?.userID);
  const [messageComm, setMsgComm] = useState("");

  const onhandleclose = () => {
    setamount("");
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (localStorage.getItem("iswalletconnect") == "yes") {
      getData();
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("iswalletconnect") == "yes") {
      getuserdata();

      getusesetting();
      getWithdrawHistory();
    }
  }, [walletConnection.address]);

  useEffect(() => {
    searchClick();
  }, [walletConnection.address, userdata]);

  const getusesetting = async () => {
    const data = await getsettings();
    console.log(data, "___");
    setSetings(data?.result);

    const data1 = await getusesettings();
    console.log(data1, "UserSettings");
    setUserSetings(data1?.result);
  };

  const getWithdrawHistory = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      const data = await getWithdrawHis(payload);
      console.log(data, "dsfvfv");
      const filDataCom = data?.result?.filter((el) => el.type == 4);
      setComHis(filDataCom);
    }
  };

  useEffect(() => {
    getdepositcommunityhistory();
  }, [walletConnection.address, userdata]);

  const getdepositcommunityhistory = async () => {
    if (walletConnection?.address) {
      var reqdata = {
        address: walletConnection.address,
        currId: userdata?.userID
      };
      const { result, status, result1 } = await get_communityhistory(reqdata);
      console.log(result, status, result1, "tfthrthfbdg");

      if (status) {
        await result?.map((ele, i) => {
          ele.SSNo = i + 1;
        });

        setCommunityhistory(result);

        await result1?.map((ele, i) => {
          ele.SSNo = i + 1;
        });

        setCommunityhistory1(result1);
      } else {
        setCommunityhistory([]);
        setCommunityhistory1([]);
      }
    }
  };

  const getuserdata = async () => {
    if (walletConnection.address) {
      console.log(walletConnection.address, "walletConnection.address");
      var reqdata = {
        address: walletConnection.address
      };
      console.log(reqdata);

      const data = await getUserDetails(reqdata);
      console.log(data, "data_data");
      setLoader(true);

      console.log(data?.data?.success, "success");

      var web3 = new Web3(walletConnection.web3);

      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );
      const tokenprice = await icoContract.methods.tokenPrice().call();
      const tokenprice1 = await icoContract.methods.tokenPrice1().call();
      var updateprice = tokenprice / 1e8;
      var updateprice1 = tokenprice1 / 1e8;
      settokenpricevalue(updateprice);
      settokenpricevaluextrc(updateprice1);
      if (data?.data?.success == true) {
        setuserdata(data?.data?.data);
        setLoader(false);
      } else {
        // navigate("/join-community");
        navigate("/join-now");
        setuserdata([]);
        setLoader(false);
      }
    }
  };

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  //register-----------community
  const registerCommunity = async () => {
    try {
      if (amount == "") {
        toastAlert("error", "Amount Required", "error");
        return false;
      }
      if (amount != userseting?.CommunityPrice) {
        toastAlert(
          "error",
          `Amount Must be multiples of ${userseting?.CommunityPrice}`,
          "error"
        );
        return false;
      }
      if (amount == 0) {
        toastAlert("error", `Amount Must be multiples of 10`, "error");
        return false;
      }

      var Referral = "0x80aa595c83430288e3a75f4d03be15b72385420f";
      var ipaddress = ip.replaceAll(".", "");
      var Nationality = "india";

      var web3 = new Web3(walletConnection.web3);

      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );
      const tokenprice = await icoContract.methods.tokenPrice().call();

      var updateprice = tokenprice / 1e8;
      var approveamt = await convert(amount * 1e18);
      approveamt = approveamt * (1 / tokenpricevalue);
      approveamt = convert(approveamt);

      var BusdBlanace = new web3.eth.Contract(
        BusdContractAbi,
        config.Busdaddress
      );

      const balance = await BusdBlanace.methods
        .balanceOf(walletConnection.address)
        .call();
      console.log(balance, "balance");
      if (balance / 1e18 < amount) {
        toastAlert("error", "Your TAURAS Token Balance is low", "error");
        return false;
      }

      setLoader(true);

      await BusdBlanace.methods
        .approve(config.Icocontract, approveamt.toString())
        .send({ gasLimit: 250000, from: walletConnection.address })
        .then(async (res) => {
          var icoContract = new web3.eth.Contract(
            IcoContractAbi,
            config.Icocontract
          );
          await icoContract.methods
            .registrationCommunity(
              approveamt.toString(),
              Referral,
              ipaddress,
              Nationality
            )
            .send({ from: walletConnection.address })
            .then(async (res) => {
              const reqdata = {
                refaddress: Referral,
                amount: amount,
                country: Nationality,
                address: walletConnection.address,
                transactionHash: res.transactionHash,
                ipaddress: ip
              };
              const result = await userRegCommunity(reqdata);

              if (result.success == true) {
                toastAlert("success", "Retopup successfully", "success");
                setLoader(false);

                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
      toastAlert("success", "Rejected", "success");
    }
  };
  //reTopup---------community
  // retopupCommunity

  const ReTopUpCommunity = async () => {
    try {
      setLoader(true);
      if (amount == "") {
        toastAlert("error", "Amount Required", "error");
        setLoader(false);
        return false;
      }
      // console.log(seting,"setingsetingseting")
      if (amount != userseting.CommunityPrice) {
        toastAlert(
          "error",
          "Amount Must be " + userseting.CommunityPrice,
          "error"
        );
        setLoader(false);
        return false;
      }
      if (amount == 0) {
        toastAlert("error", "Amount Must be multiples of 100", "error");
        setLoader(false);
        return false;
      }

      // var amount = 200;
      // var approveamt = await convert(amount * 1e18);

      var web3 = new Web3(walletConnection.web3);

      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );
      const tokenprice = await icoContract.methods.tokenPrice().call();
      let TokenCont = "";
      var BusdBlanace = new web3.eth.Contract(
        BusdContractAbi,
        config.Busdaddress
      );
      var XtrcBlanace = new web3.eth.Contract(XTRCABI, config.xtrcaddress);
      var approveamt = await convert(amount * 1e18);
      // approveamt = approveamt * (1 / tokenpricevalue);
      if (Number(userdata?.tokenType) == 0) {
        approveamt = approveamt * (1 / tokenpricevalue);
        TokenCont = BusdBlanace;
      } else {
        approveamt = approveamt * (1 / tokenpricevaluextrc);
        TokenCont = XtrcBlanace;
      }
      approveamt = convert(approveamt);

      const balance = await BusdBlanace.methods
        .balanceOf(walletConnection.address)
        .call();
      const balanceX = await XtrcBlanace.methods
        .balanceOf(walletConnection.address)
        .call();
      console.log(balance, "balance");
      if (Number(userdata?.tokenType) == 0) {
        if (balance / 1e18 < amount) {
          toastAlert("error", "Your TAURAS Token Balance is low", "error");
          setLoader(false);
          return false;
        }
      } else {
        if (balanceX / 1e18 < amount * (1 / tokenpricevaluextrc)) {
          toastAlert("error", "Your XTAURAS Token Balance is low", "error");
          setLoader(false);
          return false;
        }
      }

      await TokenCont.methods
        .approve(config.Icocontract, approveamt.toString())
        .send({ gasLimit: 250000, from: walletConnection.address })
        .then(async (res) => {
          var icoContract = new web3.eth.Contract(
            IcoContractAbi,
            config.Icocontract
          );

          await icoContract.methods
            .retopupCommunity(
              approveamt.toString(),
              (userdata?.tokenType).toString()
            )
            .send({ from: walletConnection.address })
            .then(async (res) => {
              const reqbody = {
                address: walletConnection.address,
                transactionHash: res.transactionHash,
                amount: amount,
                tokenType: userdata?.tokenType
              };

              const result = await userRetopupCommunity(reqbody);

              if (result.data.success == true) {
                toastAlert("success", "Retopup successfully", "success");
                window.$("#" + "Retopupcommunity-model").modal("hide");
                setLoader(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            });
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  const Funn = () => {
    for (let i = 0; i < communityTree.length; i++) {
      if (communityTree[i].items.key == searchId) {
        setSrchLR(true);
        break;
      }
    }
  };
  useEffect(() => {
    Funn();
  }, [searchId]);

  const searchchange = (e) => {
    var ValNum = parseInt(e.target.value);
    setSearchId(ValNum);
    // alert(VaNum);
    for (let i = 0; i < communityTree.length; i++) {
      if (communityTree[i].items.key == ValNum) {
        setSrchLR(true);
        break;
      } else {
        setSrchLR(false);
      }
    }
  };

  const searchClick = async () => {
    let payload = {
      searchId: searchId,
      userId: userdata?.userID
    };
    const data = await seaarchUserTree(payload);
    console.log(data, "datadata");

    const indexToMatch = data.user.Data1.findIndex(
      (ele) => ele?.items?.key == userdata.userID
    );
    console.log(indexToMatch, "indexToMatch");
    let newArray;
    if (indexToMatch !== -1) {
      newArray = data.user.Data1.slice(indexToMatch);
      newArray.push({
        _id: "635baa912978337ce386f3bc",
        type: 1,
        items: {
          user: 0,
          level: 0,
          parent: 0
        }
      });
      setcommunityTree(newArray);
    }
  };

  console.log(communityTree, "userdata");

  async function CopyFunction(text) {
    navigator.clipboard.writeText(text);
    setcopystatus(true);
    setTimeout(() => {
      setcopystatus(false);
    }, 2000);
    // toast.success("Copied!", toasterOption);
  }

  async function CopyFunction1(text) {
    navigator.clipboard.writeText(text);
    setcopystatus1(true);
    setTimeout(() => {
      setcopystatus1(false);
    }, 2000);
    // toast.success("Copied!", toasterOption);
  }

  const withdrawCommu = async () => {
    var amount =
      userdata?.communityIncome -
      (userdata?.communityIncome * userseting?.adminDeduction) / 100 -
      userdata?.totalWithdrawCommunity;

    if (amount <= 0) {
      toastAlert("error", "Amount must be greater than zero", "error");
      return false;
    }

    if (amount < userseting?.minimumcommunitywithdrawal) {
      toastAlert(
        "error",
        "Amount must be greater than Minimum limit " +
          userseting?.minimumcommunitywithdrawal,
        "error"
      );
      return false;
    }
    setLoader(true);
    let rand = Math.floor(Math.random() * 1000000 + 1);
    var data = {
      amount: amount,
      randNumber: rand,
      address: walletConnection.address
    };

    const signdata = await getSignatureCommu(data);
    console.log(signdata, "result");
    if (signdata?.data?.status) {
      var web3 = new Web3(walletConnection?.web3);
      var icoContract = new web3.eth.Contract(
        IcoContractAbi,
        config.Icocontract
      );

      var amountVal = amount * 10 ** 18;
      var amt = amountVal;
      amt = convert(amt);
      amt = amt.toString();

      await icoContract.methods
        .withdraw(
          amt,
          walletConnection.address,
          rand,
          signdata?.data?.result?.signature,
          "4",
          (userdata?.tokenType).toString()
        )
        .send({ from: walletConnection.address })
        .then(async (res) => {
          const reqbody = {
            address: walletConnection.address,
            transactionHash: res.transactionHash,
            signature: signdata?.data?.result?.signature
          };
          var dataUpd = await updateStatus(reqbody);
          if (dataUpd?.data?.status) {
            toastAlert("success", "Withdraw successfully", "success");
            setLoader(false);
            window.location.reload();
            return false;
          }
        })
        .catch((e) => {
          console.log(e, "er");
          setLoader(false);
          toastAlert("error", "Rejected", "error");
          return false;
        });
    } else {
      toastAlert("error", "Try Again ", "error");
      setLoader(false);
      return false;
    }
  };

  const treestructure = (userLev1) => {
    console.log(userLev1);
    return (
      <>
        {communityTree.map((ele, i) => {
          if (ele.items.parent === userLev1)
            return (
              <>
                {/* {console.log(`https://www.countries-ofthe-world.com/flags-normal/flag-of-${ele?.docs_?.country}.png`, "country")} */}
                <CustomizedIconTree
                  onClick={() => {
                    treestructure(ele.items?.key);
                  }}
                  content={
                    <span
                      className={
                        ele?.docs[0]?.communitystatus == 0 ? "id blur" : "id"
                      }
                    >
                      {" "}
                      {ele.items.key}
                      <img
                        style={{ width: "26px" }}
                        src={`https://flagicons.lipis.dev/flags/4x3/${ele?.docs[0]?.country?.toLowerCase()}.svg`}
                        alt="copy"
                        className="img-fluid ms-2"
                      />
                    </span>
                  }
                  type={
                    <i
                      class={
                        ele?.docs[0]?.communitystatus == 0
                          ? "blur fas fa-user"
                          : "fas fa-user"
                      }
                    ></i>
                  }
                >
                  {treestructure(ele.items?.key)}
                </CustomizedIconTree>
              </>
            );
        })}
      </>
    );
  };

  const cancelStatus = async (amount, rand, sig, type) => {
    var web3 = new Web3(walletConnection?.web3);
    var icoContract = new web3.eth.Contract(IcoContractAbi, config.Icocontract);

    var amountVal = amount * 10 ** 18;
    var amt = amountVal;
    amt = convert(amt);
    amt = amt.toString();
    setLoader(true);
    await icoContract.methods
      .cancelWithdraw(amt, walletConnection.address, rand, sig, type)
      .send({ from: walletConnection.address })
      .then(async (res) => {
        const reqbody = {
          transactionHash: res.transactionHash,
          signature: sig,
          address: walletConnection.address
        };
        var canData = await cancelStatusCommu(reqbody);
        if (canData?.data?.status) {
          toastAlert("success", "Cancel successfully", "success");
          setLoader(false);
          window.location.reload();
          return false;
        } else {
          toastAlert("error", "Cancel Failed", "success");
          setLoader(false);
          return false;
        }
      })
      .catch((e) => {
        toastAlert("error", "Rejected", "error");
        setLoader(false);
        return false;
      });
  };
  function convertToDubaiTime(date) {
    const inputDate = new Date(date);
    const utcTime = inputDate.getTime();
    const dubaiOffset = 5400 * 1000;
    const dubaiTime = new Date(utcTime - dubaiOffset);
    return dubaiTime;
  }
  const communitycolumns = [
    {
      name: "S.no",
      selector: (row, index) => index + 1
    },
    {
      name: "Amount",
      selector: (row) => row.amount
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    // {
    //   name: "ReferalId1",
    //   selector: (row, index) => row.userID == row.ReferalId1 ? row.ReferalId1 :
    //     row.userID == row.ReferalId2 ? row.ReferalId2 :
    //       row.userID == row.ReferalId3 ? row.ReferalId3 :
    //         row.userID == row.ReferalId4 ? row.ReferalId4 :
    //           row.userID == row.ReferalId5 ? row.ReferalId5 :
    //             row.userID == row.ReferalId6 ? row.ReferalId6 :
    //               row.userID == row.ReferalId7 ? row.ReferalId7 : "",
    // },

    {
      name: "Referal Amount",
      selector: (row, index) =>
        userdata?.userID == parseFloat(row.ReferalId1)
          ? row.ReferalAmount1.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId2)
          ? row.ReferalAmount2.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId3)
          ? row.ReferalAmount3.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId4)
          ? row.ReferalAmount4.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId5)
          ? row.ReferalAmount5.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId6)
          ? row.ReferalAmount6.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? row.ReferalAmount7.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? row.ReferalAmount8.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? row.ReferalAmount9.toFixed(4)
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? row.ReferalAmount10.toFixed(4)
          : ""
    },
    {
      name: "Referal (%)",
      selector: (row, index) =>
        userdata?.userID == parseFloat(row.ReferalId1)
          ? seting.UplineIncome1
          : userdata?.userID == parseFloat(row.ReferalId2)
          ? seting.UplineIncome2
          : userdata?.userID == parseFloat(row.ReferalId3)
          ? seting.UplineIncome3
          : userdata?.userID == parseFloat(row.ReferalId4)
          ? seting.UplineIncome4
          : userdata?.userID == parseFloat(row.ReferalId5)
          ? seting.UplineIncome5
          : userdata?.userID == parseFloat(row.ReferalId6)
          ? seting.UplineIncome6
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? seting.UplineIncome7
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? seting.UplineIncome8
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? seting.UplineIncome9
          : userdata?.userID == parseFloat(row.ReferalId7)
          ? seting.UplineIncome10
          : ""
    },
    // {
    //   name: "Hash",
    //   width: "200px",
    //   selector: (row, index) => halfAddrShow(row.transHash),
    // },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];

  const communitycolumns1 = [
    {
      name: "S.no",
      selector: (row, index) => index + 1
    },
    {
      name: "Amount",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    {
      name: "Date",
      width: "250PX",
      selector: (row) =>
        moment(convertToDubaiTime(row.createdAt)).format("MMM,Do YYYY, hh:mm A")
    }
  ];

  const communitywith = [
    {
      name: "S.No",
      selector: (row, index) => index + 1
    },
    {
      name: "User ID",
      selector: (row) => row.userID
    },
    {
      name: "Amount",
      selector: (row) => row.amount.toFixed(4)
    },
    {
      name: "Status",
      selector: (row) =>
        row.Status == 1 ? "Created" : row.Status == 2 ? "Approved" : "Cancel"
    },
    {
      name: "Action",
      selector: (row) =>
        row.Status == 1 ? (
          <button
            className="primary_btn py-1 px-4"
            onClick={() =>
              cancelStatus(row.amount, row.nonce, row.sig, row.type)
            }
          >
            Cancel
          </button>
        ) : row.Status == 2 ? (
          ""
        ) : (
          ""
        )
    },
    {
      name: "Hash",
      selector: (row) =>
        row.thash ? (
          <Link target="_blank" to={config.bscscan + row.thash}>
            {halfAddrShow(row.thash)}
          </Link>
        ) : (
          "-"
        )
    }
  ];

  return (
    <>
      {isLoader == true && (
        <ReactLoading type={"spokes"} color={"#000000"} className="loading" />
      )}
      <div>
        <Navbar />
        {userdata?.blackUser && (
          <h1 className="blackText">Your Blocked by admin</h1>
        )}
        <div
          className={`dashboard community ${
            userdata?.blackUser == true ? "Opacity" : ""
          }`}
        >
          <div className="container">
            <div className="dashboard_box">
              <div className="head_flx">
                <h2 className="h2tag mb-0">Dashboard</h2>
                <div
                  className="wallet"
                  onClick={() => CopyFunction(walletConnection.address)}
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    {" "}
                    Your Wallet :
                    <span> {halfAddrShow(walletConnection.address)}</span>
                  </span>
                  {!copystatus ? (
                    <img
                      src={require("../assets/images/copy.png")}
                      alt="copy"
                      className="img-fluid ms-3"
                      width="20px"
                    />
                  ) : (
                    <i
                      class="fa fa-check-circle ms-3"
                      aria-hidden="true"
                      style={{ color: "green", fontSize: "20px" }}
                    ></i>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="box">
                    <div className="inbox right_bdr">
                      <div>
                        <p>My Present ID</p>
                        <h5>{userdata?.userID}</h5>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../assets/images/user.png")}
                          alt="user"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="inbox right_bdr">
                      <div>
                        <p>Direct Referral</p>
                        <h5>{userdata?.directReferalUsers}</h5>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../assets/images/refferal.png")}
                          alt="refferal"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="inbox">
                      <div>
                        <p>Community Income</p>
                        {console.log(seting, "seting", userseting)}
                        <h5>
                          {(
                            userdata?.communityIncome -
                            (userdata?.communityIncome *
                              userseting?.adminDeduction) /
                              100 -
                            userdata?.totalWithdrawCommunity
                          ).toFixed(4)}{" "}
                          USD
                        </h5>
                        <span>
                          {Number(userdata?.tokenType) == 1 ? (
                            <>
                              (
                              {(
                                ((userdata?.communityIncome -
                                  (userdata?.communityIncome *
                                    userseting?.adminDeduction) /
                                    100 -
                                  userdata?.totalWithdrawCommunity) *
                                  1) /
                                tokenpricevaluextrc
                              ).toFixed(4)}{" "}
                              XTAURAS )
                            </>
                          ) : (
                            <>
                              (
                              {(
                                ((userdata?.communityIncome -
                                  (userdata?.communityIncome *
                                    userseting?.adminDeduction) /
                                    100 -
                                  userdata?.totalWithdrawCommunity) *
                                  1) /
                                tokenpricevalue
                              ).toFixed(4)}{" "}
                              TAURAS )
                            </>
                          )}
                        </span>
                        {userdata?.communityIncome -
                          (userdata?.communityIncome *
                            userseting?.adminDeduction) /
                            100 -
                          userdata?.totalWithdrawCommunity >
                          0 && (
                          <button
                            className="primary_btn px-6 py-1"
                            // disabled={true}
                            style={{
                              margin: "10px 0px 0px 0px",
                              display: "flex"
                            }}
                            onClick={withdrawCommu}
                          >
                            Withdraw
                          </button>
                        )}
                      </div>
                      <div className="icon">
                        <img
                          src={require("../assets/images/income.png")}
                          alt="limit"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="banner_btn_grp"
                style={{
                  marginBottom: "20px",
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center"
                }}
              >
                {/* {userdata.communityOnly == 0 ||
                  (userdata.length == 0 && (
                    <button
                      className="primary_btn primary_btn_flx"
                      data-bs-toggle="modal"
                      data-bs-target="#joinRetopupcommunity-model"
                    >
                      User Register Community
                      <span className="primary_btn_arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M9.66455 4.63121L6.19177 1.14606C6.09264 1.04658 5.96051 0.991974 5.81963 0.991974C5.67859 0.991974 5.54655 1.04666 5.44741 1.14606L5.13211 1.46256C5.03306 1.56189 4.97849 1.69456 4.97849 1.83602C4.97849 1.9774 5.03306 2.11454 5.13211 2.21387L7.15808 4.25148H0.721475C0.431269 4.25148 0.201965 4.47947 0.201965 4.77079V5.21823C0.201965 5.50954 0.431269 5.76052 0.721475 5.76052H7.18107L5.13219 7.80951C5.03313 7.909 4.97856 8.03806 4.97856 8.17952C4.97856 8.32082 5.03313 8.45176 5.13219 8.55117L5.44749 8.86664C5.54662 8.96613 5.67867 9.02034 5.81971 9.02034C5.96059 9.02034 6.09271 8.96542 6.19185 8.86594L9.66463 5.38087C9.764 5.28107 9.81864 5.14785 9.81825 5.00624C9.81857 4.86415 9.764 4.73085 9.66455 4.63121Z"
                            fill="#041E37"
                          />
                        </svg>
                      </span>
                    </button>
                  ))} */}
                {userdata?.communitystatus == 0 && (
                  <button
                    className="primary_btn primary_btn_flx"
                    data-bs-toggle="modal"
                    // disabled={true}
                    data-bs-target="#Retopupcommunity-model"
                  >
                    User Retopup Community
                    <span className="primary_btn_arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          d="M9.66455 4.63121L6.19177 1.14606C6.09264 1.04658 5.96051 0.991974 5.81963 0.991974C5.67859 0.991974 5.54655 1.04666 5.44741 1.14606L5.13211 1.46256C5.03306 1.56189 4.97849 1.69456 4.97849 1.83602C4.97849 1.9774 5.03306 2.11454 5.13211 2.21387L7.15808 4.25148H0.721475C0.431269 4.25148 0.201965 4.47947 0.201965 4.77079V5.21823C0.201965 5.50954 0.431269 5.76052 0.721475 5.76052H7.18107L5.13219 7.80951C5.03313 7.909 4.97856 8.03806 4.97856 8.17952C4.97856 8.32082 5.03313 8.45176 5.13219 8.55117L5.44749 8.86664C5.54662 8.96613 5.67867 9.02034 5.81971 9.02034C5.96059 9.02034 6.09271 8.96542 6.19185 8.86594L9.66463 5.38087C9.764 5.28107 9.81864 5.14785 9.81825 5.00624C9.81857 4.86415 9.764 4.73085 9.66455 4.63121Z"
                          fill="#041E37"
                        />
                      </svg>
                    </span>
                  </button>
                )}
              </div>
              <div className="row">
                {/* <div className="col-lg-6 col-xxl-6">
                  <div className="box">
                    <div className="w-100">
                      <p className="mb-2">Referral Link</p>
                      <div
                        className="link"
                        onClick={() =>
                          CopyFunction1(
                            config.frontend +
                              "/join-community/" +
                              userdata?.userID
                          )
                        }
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <div className="half">
                          {config.frontend +
                            "/join-community/" +
                            userdata?.userID}
                        </div>
                        <div className="ms-3">
                          {!copystatus1 ? (
                            <img
                              src={require("../assets/images/copy.png")}
                              alt="copy"
                              className="img-fluid ms-3"
                              width="20px"
                            />
                          ) : (
                            <i
                              class="fa fa-check-circle ms-3"
                              aria-hidden="true"
                              style={{ color: "green", fontSize: "20px" }}
                            ></i>
                          )}
                        </div>
                      </div>
                      <ul>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-x-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fab fa-telegram-plane"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-brands fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <i class="fa-solid fa-phone"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-3 col-xxl-3">
                  <div className="box">
                    <div className="w-100">
                      <p className="mb-2">Username</p>
                      <h5>{userdata?.userName}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-xxl-3">
                  <div className="box">
                    <div className="w-100">
                      <p className="mb-2">User Status</p>
                      <h5>
                        {userdata?.communitystatus == 1 ? "Active" : "Inactive"}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Community Tree
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="box_flx m-0">
                            <div class="input-group">
                              {console.log(searchId, "ewfdcf")}
                              <input
                                type="text"
                                class="form-control pe-1"
                                placeholder="Search ID"
                                onChange={searchchange}
                                value={searchId ? searchId : ""}
                              />
                              <span class="input-group-text" id="basic-addon2">
                                <img
                                  src={search}
                                  className="img-fluid"
                                  alt="Icon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="user_flx ">
                            <span style={{ marginRight: "16px" }}>Note :</span>
                            <div className="me-lg-4">
                              <img
                                src={require("../assets/images/active_user.png")}
                                alt="copy"
                                className="img-fluid me-3"
                              />
                              <p>Active User’s</p>
                            </div>
                            <div>
                              <img
                                src={require("../assets/images/inactive_user.png")}
                                alt="copy"
                                className="img-fluid me-3"
                              />
                              <p>Inactive User’s</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {searchId && searchId != "" ? (
                        <div className="tree">
                          {userdata?.userID == parseInt(searchId)}

                          {/* <span>ID {searchId} </span> */}
                          <>
                            {communityTree.map((ele, i) => {
                              if (ele.items.key === parseInt(searchId))
                                return (
                                  <CustomizedIconTree
                                    content={
                                      <span
                                        className={
                                          ele?.docs?.[0]?.communitystatus == 0
                                            ? "id blur"
                                            : "id"
                                        }
                                      >
                                        {" "}
                                        ID {searchId}
                                        <img
                                          style={{ width: "26px" }}
                                          src={`https://flagicons.lipis.dev/flags/4x3/${ele?.docs?.[0]?.country?.toLowerCase()}.svg`}
                                          alt="copy"
                                          className="img-fluid ms-2"
                                        />
                                      </span>
                                    }
                                    type={
                                      <i
                                        class={
                                          ele?.docs?.[0]?.communitystatus == 0
                                            ? "blur fas fa-user"
                                            : "fas fa-user"
                                        }
                                      ></i>
                                    }
                                  >
                                    <>
                                      {communityTree.map((ele, i) => {
                                        if (
                                          ele.items.parent ===
                                          parseInt(searchId)
                                        )
                                          return (
                                            <CustomizedIconTree
                                              onClick={() => {
                                                treestructure(ele.items?.key);
                                              }}
                                              content={
                                                <span
                                                  className={
                                                    ele?.docs?.[0]
                                                      ?.communitystatus == 0
                                                      ? "id blur"
                                                      : "id"
                                                  }
                                                >
                                                  {" "}
                                                  {ele.items.key}
                                                  <img
                                                    style={{ width: "26px" }}
                                                    src={`https://flagicons.lipis.dev/flags/4x3/${ele?.docs[0]?.country?.toLowerCase()}.svg`}
                                                    alt="copy"
                                                    className="img-fluid ms-2"
                                                  />
                                                </span>
                                              }
                                              type={
                                                <i
                                                  class={
                                                    ele?.docs?.[0]
                                                      ?.communitystatus == 0
                                                      ? "blur fas fa-user"
                                                      : "fas fa-user"
                                                  }
                                                ></i>
                                              }
                                            >
                                              {treestructure(ele.items?.key)}
                                            </CustomizedIconTree>
                                          );
                                      })}
                                    </>
                                  </CustomizedIconTree>
                                );
                            })}
                          </>
                        </div>
                      ) : (
                        <div className="tree">
                          <CustomizedIconTree
                            content={
                              <span
                                className={
                                  userdata?.communitystatus == 0
                                    ? "id blur"
                                    : "id"
                                }
                              >
                                {" "}
                                ID {userdata?.userID}
                                <img
                                  style={{ width: "26px" }}
                                  src={`https://flagicons.lipis.dev/flags/4x3/${userdata?.country?.toLowerCase()}.svg`}
                                  alt="copy"
                                  className="img-fluid ms-2"
                                />
                              </span>
                            }
                            type={
                              <i
                                class={
                                  userdata?.communitystatus == 0
                                    ? "blur fas fa-user"
                                    : "fas fa-user"
                                }
                              ></i>
                            }
                          >
                            {communityTree.map((ele, i) => {
                              if (
                                ele.items.parent === parseInt(userdata?.userID)
                              )
                                return (
                                  <>
                                    <CustomizedIconTree
                                      onClick={() => {
                                        treestructure(ele.items?.key);
                                      }}
                                      content={
                                        <span
                                          className={
                                            ele?.docs?.[0]?.communitystatus == 0
                                              ? "id blur"
                                              : "id"
                                          }
                                        >
                                          {ele.items.key}
                                          <img
                                            style={{ width: "26px" }}
                                            src={`https://flagicons.lipis.dev/flags/4x3/${ele?.docs[0]?.country?.toLowerCase()}.svg`}
                                            alt="copy"
                                            className="img-fluid ms-2"
                                          />
                                        </span>
                                      }
                                      type={
                                        <i
                                          class={
                                            ele?.docs?.[0]?.communitystatus == 0
                                              ? "blur fas fa-user"
                                              : "fas fa-user"
                                          }
                                        ></i>
                                      }
                                    >
                                      {treestructure(ele.items?.key)}
                                    </CustomizedIconTree>
                                  </>
                                );
                            })}
                          </CustomizedIconTree>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Community History
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={communitycolumns1}
                          data={Communityhistory1}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      Investment History
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={communitycolumns1}
                          data={Communityhistory1}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      Income History
                    </button>
                  </h2>
                  <div
                    id="collapsefour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={communitycolumns}
                          data={Communityhistory}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsesix"
                      aria-expanded="false"
                      aria-controls="collapsesix"
                    >
                      Community Withdraw History
                    </button>
                  </h2>
                  <div
                    id="collapsesix"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div class="accordion-body pt-3">
                      <div class="table-responsive">
                        <DataTable
                          columns={communitywith}
                          data={commuHis}
                          noHeader
                          pagination={true}
                          paginationPerPage="10"
                          paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        className="modal fade primary_modal"
        id="joinRetopupcommunity-model"
        tabIndex={-1}
        aria-labelledby="Retopupcommunity-ModalLabel"
        aria-hidden="true"
        // data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="Retopupcommunity-ModalLabel">
                Join community
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onhandleclose}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <label for="Referralid" class="form-label">
                    Amount
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="amount"
                    value={amount}
                    id="amount"
                    // aria-describedby="amount"
                    onInput={validPositive}
                    placeholder="Amount"
                    onChange={(e) => setamount(e.target.value)}
                  />
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      USD Amount
                    </label>

                    <input
                      type="text"
                      class="form-control"
                      name="amount"
                      value={Math.round(
                        parseFloat(amount) * (1 / parseFloat(tokenpricevalue))
                      )}
                      id="amount"
                      aria-describedby="amount"
                      onInput={validPositive}
                      placeholder="USD Amount"
                    />
                  </div>
                  <div class="my-3">
                    <button className="primary_btn" onClick={registerCommunity}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade primary_modal"
        id="Retopupcommunity-model"
        tabIndex={-1}
        aria-labelledby="Retopupcommunity-ModalLabel"
        aria-hidden="true"
        // data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="Retopupcommunity-ModalLabel">
                Re-Topup Community
                {/* {userdata?.communitystatus == 1
                  ? "Re-Topup Community"
                  : "Join Community"} */}
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onhandleclose}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {amount > 0 ? (
                  <h4
                    style={{
                      fontSize: "15px",
                      textAlign: "center",
                      border: "2px dashed #0f283a",
                      padding: "15px"
                    }}
                  >
                    {" "}
                    {`You invest only ${userseting?.CommunityPrice} USD & Expired ${userseting?.RenevalCommunityTime} days after`}
                  </h4>
                ) : (
                  ""
                )}
                <div className="m-2 mb-3 mt-3">
                  <label for="Referralid" class="form-label">
                    Amount in USD
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="amount"
                    value={amount}
                    id="amount"
                    // aria-describedby="amount"
                    onInput={validPositive}
                    placeholder="Amount"
                    onChange={(e) => setamount(e.target.value)}
                  />
                  <div class="mb-3">
                    <label for="Referralid" class="form-label">
                      Amount in{" "}
                      {Number(userdata?.tokenType) == 0 ? "TAURAS" : "XTAURAS"}
                    </label>

                    <input
                      type="text"
                      class="form-control"
                      name="amount"
                      value={
                        Number(userdata?.tokenType) == 0
                          ? isNaN(
                              Math.round(Number(amount) * (1 / tokenpricevalue))
                            )
                            ? 0
                            : Math.round(Number(amount) * (1 / tokenpricevalue))
                          : isNaN(
                              Math.round(
                                Number(amount) * (1 / tokenpricevaluextrc)
                              )
                            )
                          ? 0
                          : Math.round(
                              Number(amount) * (1 / tokenpricevaluextrc)
                            )
                        // Math.round(Number(amount) * (1 / tokenpricevalue)) ==
                        // NaN
                        //   ? 0
                        //   : Math.round(Number(amount) * (1 / tokenpricevalue))
                      }
                      id="amount"
                      aria-describedby="amount"
                      onInput={validPositive}
                      placeholder="USD Amount"
                    />
                  </div>
                  <div class="my-3">
                    <button className="primary_btn" onClick={ReTopUpCommunity}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
